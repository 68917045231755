import React, { useEffect, useState, useCallback } from "react";
import Template from "../utils/Template";
import { ToastContainer } from "react-toastify";
import { getMethodService, postMethodService } from "../services";
import { addDays, format, subDays } from "date-fns";
import type { RangeKeyDict } from "react-date-range";

import {
  BET_LISTS_API,
  CHANGE_PASSWORD_API,
  LOGIN_API,
  WINNER_API,
  SENIORS_LIST_API,
  USERS_API,
  BET_USER_TRANSACTION_API,
} from "../services/constants";
import { useNavigate } from "react-router-dom";
import { HandleLogout } from "../utils/Helper";
import TableScroll from "../utils/TableScroll";
import TableLoading from "../utils/TableLoading";
import { useQuery } from "@tanstack/react-query";
import { stateObjType } from "../types";
import { DateRangePickerComponent } from "../utils/DateRange";
import { momentDateFormat, betType as BetTypeHelper } from "../services/helper";
import type { Range } from "react-date-range";

const BetsTransaction = ({ type, betType, endPoint }: any) => {
  const [isLoading, setIsLoading] = useState(false);
  const [bets, setBets] = useState([]);
  const [refreshKey, setRefreshKey] = useState(0);
  const navigate = useNavigate();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [user, setUser] = useState<any>("all");
  const [seniorOptions, setSeniorOptions] = useState<any>("");
  const fetchBetsTransacton = () =>
    getMethodService(
      `${BET_USER_TRANSACTION_API}/user-betting-transaction?start_date=${startDate}&end_date=${endDate}&user_id=${
        user === "all" ? null : JSON.parse(user)?.id
      }`
    );
  const betsQuery = useQuery({
    queryKey: [refreshKey, startDate, endDate, user],
    queryFn: fetchBetsTransacton,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (betsQuery) {
      setIsLoading(betsQuery.isLoading);
      if (
        betsQuery?.data === "Unauthenticated." ||
        betsQuery?.data === "E_UNAUTHORIZED_ACCESS: Unauthorized access"
      ) {
        HandleLogout(navigate);
      }
      if (betsQuery?.data?.status === "success") {
        console.log("Hello", betsQuery?.data?.data);
        setBets(betsQuery?.data?.data);
      }
    }
  }, [betsQuery]);

  const handleChangeValueDateRangePicker = useCallback(
    (start: any, end: any, label: any) => {
      console.log(label);
      setStartDate(start.format("YYYY-MM-DD"));
      setEndDate(end.format("YYYY-MM-DD"));
    },
    []
  );

  const fetchUsers = () => getMethodService(`${USERS_API}/users?user`);
  const userQuery = useQuery({
    queryKey: [refreshKey],
    queryFn: fetchUsers,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (userQuery) {
      setIsLoading(userQuery.isLoading);
      if (
        userQuery?.data === "Unauthenticated." ||
        userQuery?.data === "E_UNAUTHORIZED_ACCESS: Unauthorized access"
      ) {
        HandleLogout(navigate);
      }
      if (userQuery?.data?.status === "success") {
        setSeniorOptions(userQuery?.data?.data);
      }
    }
  }, [userQuery]);

  return (
    <>
      <div className="card mb-3 mb-lg-5">
        <div className="card-header">
          <div className="row align-items-center">
            <div className="col-sm-2">
              <h4 className="card-title">Betting Transaction</h4>
            </div>
            <div className="col-sm-3">
              <div className="tom-select-custom tom-select-custom-with-tags">
                <div className="tom-select-custom">
                  <select
                    className="js-select form-select"
                    value={user}
                    autoComplete="off"
                    data-hs-tom-select-options='{
                                                "placeholder": "Select user..."
                                            }'
                    onChange={(e) => setUser(e.target.value)}
                  >
                    <option value="all">All</option>
                    {seniorOptions?.length > 0 &&
                      seniorOptions.map((senior: stateObjType) => (
                        <option key={senior?.id} value={JSON.stringify(senior)}>
                          {senior?.username}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
            </div>
            <div className="col-sm-7 d-flex justify-content-end">
              <DateRangePickerComponent
                startDate={startDate}
                endDate={endDate}
                onChange={handleChangeValueDateRangePicker}
              />
            </div>
          </div>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-sm-12">
              <TableScroll>
                <div>
                  <table className="table text-white w-100 table-bordered table-striped">
                    <thead>
                      <tr style={{ backgroundColor: "rgb(70 70 227)" }}>
                        <th className="p-2 text-center text-light">No</th>
                        <th className="p-2 text-center text-light">User</th>
                        <th className="p-2 text-center text-light">
                          Bet Amount
                        </th>
                        <th className="p-2 text-center text-light">
                          Before Amount
                        </th>
                        <th className="p-2 text-center text-light">
                          After Amount
                        </th>
                        <th className="p-2 text-center text-light"> Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      {isLoading && <TableLoading col={7} />}
                      {bets.length > 0 ? (
                        bets.map((res: stateObjType, i: number) => (
                          <tr style={{ verticalAlign: "middle" }} key={res?.id}>
                            <td className="p-2 text-center text-dark">
                              {i + 1}
                            </td>
                            <td className="p-2 text-center text-dark">
                              {res?.users?.name}
                            </td>
                            <td className="p-2 text-center text-dark">
                              {res?.bet_amount}
                            </td>
                            <td className="p-2 text-center text-dark">
                              {res?.before_amount}
                            </td>
                            <td className="p-2 text-center text-dark">
                              {res?.after_amount}
                            </td>

                            <td className="p-2 text-center text-dark">
                              {momentDateFormat(res?.updated_at)}
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td className="text-center" colSpan={7}>
                            No found data
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </TableScroll>
            </div>
          </div>
        </div>
      </div>

      <ToastContainer />
    </>
  );
};

export default BetsTransaction;
