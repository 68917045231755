import React, { useEffect, useState } from "react";
import {
  Route,
  BrowserRouter as Router,
  Routes,
  useNavigate,
} from "react-router-dom";
import Login from "../auths/Login";

import Protect from "./Protect";

import { AUTHENTICATED_ROUTE } from "../contants/routes";
import Template from "../utils/Template";

const Routers = () => {
  return (
    <Router basename="/">
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/" element={<Template />}>
          {AUTHENTICATED_ROUTE.map((r) => {
            const Component = r.component;
            const path = r.path;
            return (
              <Route
                key={path}
                path={path}
                element={
                  <Protect>
                    <Component {...r.props} />
                  </Protect>
                }
              />
            );
          })}
        </Route>
      </Routes>
    </Router>
  );
};

export default Routers;
