import React, { useEffect, useState, useCallback } from 'react'
import Template from '../utils/Template'
import { ToastContainer } from 'react-toastify'
import { getMethodService, postMethodService } from '../services';
import { RESULT_API } from '../services/constants';
import { useNavigate } from 'react-router-dom';
import { HandleLogout } from '../utils/Helper';
import TableScroll from '../utils/TableScroll';
import { useQuery } from '@tanstack/react-query';
import TableLoading from '../utils/TableLoading';
import ModalBox from '../utils/ModalBox';
import { Form, FormGroup, Input, Label } from 'reactstrap';
import EasyEdit from 'react-easy-edit';
import '../assets/styles/EasyEdit.css';
import { useSelector } from 'react-redux';
import { DateRangePickerComponent } from '../utils/DateRange';
import { momentDateFormat, momentTimeFormat, betType } from '../services/helper';

const Result = ({type,resultType,getEndPoint,addEndPoint,editEndPoint}) => {
    const [isLoading,setIsLoading] = useState(false);
    const [result,setResult] = useState([]);
    const [refreshKey, setRefreshKey] = useState(0);
    const [createResultModal,setCreateResultModal] = useState(false);
    const [createGoldResultModal,setCreateGoldResultModal] = useState(false);
    const [createDubaiResultModal,setCreateDubaiResultModal] = useState(false);
    const [createMalayResultModal,setCreateMalayResultModal] = useState(false);
    const [threedCreateResultModal,setThreedCreateResultModal] = useState(false);
    const [threedEditResultModal,setThreedEditResultModal] = useState(false);
    const [threedCreateDubaiResultModal,setThreedCreateDubaiResultModal] = useState(false);
    const [threedCreateGoldResultModal,setThreedCreateGoldResultModal] = useState(false);

    const [createBTCResultModal,setCreateBTCResultModal] = useState(false);

    const [editData,setEditData] = useState(null);
    const { user_info } = useSelector((state) => state.userInfo);
    const [startDate , setStartDate] = useState(null);
    const [endDate , setEndDate] = useState(null);
    const [digit,setDigit] = useState("")
    const [votes,setVotes] = useState("")
    const [lotteryTime,setLotteryTime] = useState("")
    const navigate = useNavigate();
    
    const fetchResult = () => getMethodService(`${RESULT_API}${getEndPoint}?type=${resultType}&start_date=${startDate}&end_date=${endDate}`);
    const resultQuery = useQuery({ queryKey: [refreshKey,type , startDate,endDate], queryFn: fetchResult, refetchOnWindowFocus: false});
    const handleChangeValueDateRangePicker = useCallback(
        (start , end , label ) => {
          console.log(label);
          setStartDate(start.format('YYYY-MM-DD'))
          setEndDate(end.format('YYYY-MM-DD'))
    
        },
        []
      );
    useEffect(() => {
        if(resultQuery){
            setIsLoading(resultQuery.isLoading);
            if(resultQuery?.data === 'Unauthenticated.' || resultQuery?.data === 'E_UNAUTHORIZED_ACCESS: Unauthorized access'){
                HandleLogout(navigate);
            };
            if(resultQuery?.data?.status === "success"){
                setResult(resultQuery?.data?.data);
            };
        };
    }, [resultQuery]);

    useEffect(()=>{
        setDigit(editData?.number);
        setVotes(editData?.votes?.toString());
    },[editData])

    const createResultHandler = (e) => {
        e.preventDefault();
        setIsLoading(true);
        const data = {
            two_digit_no: digit,
            time: lotteryTime,
            type: "MM"
        };
        postMethodService(`${RESULT_API}${addEndPoint}`, data, true)
        .then(res => {
          setIsLoading(false);
          if(res === 'Unauthenticated.' || res === "E_UNAUTHORIZED_ACCESS: Unauthorized access"){
              HandleLogout(navigate);
          };
          if(res?.status === "success"){
            setRefreshKey(prev => prev + 1);
            setCreateResultModal(false);
          };
        })
    };

    const createGoldResultHandler = (e) => {
        e.preventDefault();
        setIsLoading(true);
        const data = {
            two_digit_no: digit,
            time: lotteryTime,
            type: "GL"
        };
        postMethodService(`${RESULT_API}${addEndPoint}`, data, true)
        .then(res => {
          setIsLoading(false);
          if(res === 'Unauthenticated.' || res === "E_UNAUTHORIZED_ACCESS: Unauthorized access"){
              HandleLogout(navigate);
          };
          if(res?.status === "success"){
            setRefreshKey(prev => prev + 1);
            setCreateGoldResultModal(false);
          };
        })
    };

    const createBTCResultHandler = (e) => {
        e.preventDefault();
        setIsLoading(true);
        const data = {
            two_digit_no: digit,
            time: lotteryTime,
            type: "BTC"
        };
        postMethodService(`${RESULT_API}${addEndPoint}`, data, true)
        .then(res => {
          setIsLoading(false);
          if(res === 'Unauthenticated.' || res === "E_UNAUTHORIZED_ACCESS: Unauthorized access"){
              HandleLogout(navigate);
          };
          if(res?.status === "success"){
            setRefreshKey(prev => prev + 1);
            setCreateBTCResultModal(false);
          };
        })
    };

    const createDubaiResultHandler = (e) => {
        e.preventDefault();
        setIsLoading(true);
        const data = {
            two_digit_no: digit,
            time: lotteryTime,
            type: "DB"
        };
        postMethodService(`${RESULT_API}${addEndPoint}`, data, true)
        .then(res => {
          setIsLoading(false);
          if(res === 'Unauthenticated.' || res === "E_UNAUTHORIZED_ACCESS: Unauthorized access"){
              HandleLogout(navigate);
          };
          if(res?.status === "success"){
            setRefreshKey(prev => prev + 1);
            setCreateDubaiResultModal(false);
          };
        })
    };

    const createMalayResultHandler = (e) => {
        e.preventDefault();
        setIsLoading(true);
        const data = {
            two_digit_no: digit,
            time: lotteryTime,
            type: "ML"
        };
        postMethodService(`${RESULT_API}${addEndPoint}`, data, true)
        .then(res => {
          setIsLoading(false);
          if(res === 'Unauthenticated.' || res === "E_UNAUTHORIZED_ACCESS: Unauthorized access"){
              HandleLogout(navigate);
          };
          if(res?.status === "success"){
            setRefreshKey(prev => prev + 1);
            setCreateMalayResultModal(false);
          };
        })
    };
    

    const statusHandler = (id,stat) => {
        setIsLoading(true);
        const data = {
            status: stat
        };
        postMethodService(`${RESULT_API}${editEndPoint}/${id}`, data, true)
        .then(res => {
          setIsLoading(false);
          if(res === 'Unauthenticated.' || res === "E_UNAUTHORIZED_ACCESS: Unauthorized access"){
              HandleLogout(navigate);
          };
          if(res?.status === "success"){
            setRefreshKey(prev => prev + 1);
            setCreateResultModal(false);
          };
        })
    };

    const threedCreateResultHandler = (e) => {
        e.preventDefault();
        setIsLoading(true);
        const data = {
            three_digit_no: digit,
            bets: votes?.split(",").map((vote) => {return {digit: vote}}),
            type: "MM",
            time: lotteryTime,
        };
        postMethodService(`${RESULT_API}${addEndPoint}`, data, true)
        .then(res => {
          setIsLoading(false);
          if(res === 'Unauthenticated.' || res === "E_UNAUTHORIZED_ACCESS: Unauthorized access"){
              HandleLogout(navigate);
          };
          if(res?.status === "success"){
            setRefreshKey(prev => prev + 1);
            setThreedCreateResultModal(false);
          };
        });
    };
    const threedEditResultHandler = (e) => {
        e.preventDefault();
        setIsLoading(true);
        const data = {
            three_digit_no: digit,
            bets: votes?.split(",").map((vote) => {return {digit: vote}}),
            type: "MM",
        };
        if(editData?.id){
            postMethodService(`${RESULT_API}${getEndPoint}/${editData?.id}/update`, data, true)
            .then(res => {
              setIsLoading(false);
              if(res === 'Unauthenticated.' || res === "E_UNAUTHORIZED_ACCESS: Unauthorized access"){
                  HandleLogout(navigate);
              };
              if(res?.status === "success"){
                setRefreshKey(prev => prev + 1);
                setThreedEditResultModal(false);
              };
            });
        };  
    };

    const threedCreateDubaiResultHandler = (e) => {
        e.preventDefault();
        setIsLoading(true);
        const data = {
            three_digit_no: digit,
            bets: votes?.split(",").map((vote) => {return {digit: vote}}),
            type: "DB",
            time: "1",
        };
        postMethodService(`${RESULT_API}${addEndPoint}`, data, true)
        .then(res => {
          setIsLoading(false);
          if(res === 'Unauthenticated.' || res === "E_UNAUTHORIZED_ACCESS: Unauthorized access"){
              HandleLogout(navigate);
          };
          if(res?.status === "success"){
            setRefreshKey(prev => prev + 1);
            setThreedCreateDubaiResultModal(false);
          };
        });
    };

    const threedCreateGoldResultHandler = (e) => {
        e.preventDefault();
        setIsLoading(true);
        const data = {
            three_digit_no: digit,
            bets: votes?.split(",").map((vote) => {return {digit: vote}}),
            type: "GL",
            time: "1",
        };
        postMethodService(`${RESULT_API}${addEndPoint}`, data, true)
        .then(res => {
          setIsLoading(false);
          if(res === 'Unauthenticated.' || res === "E_UNAUTHORIZED_ACCESS: Unauthorized access"){
              HandleLogout(navigate);
          };
          if(res?.status === "success"){
            setRefreshKey(prev => prev + 1);
            setThreedCreateDubaiResultModal(false);
          };
        });
    };

    const editTwoDigitHandler = (value,id) => {
        setIsLoading(true);
        const data = {
            two_digit_no: value,
        };
        postMethodService(`${RESULT_API}${getEndPoint}/${id}/update`, data, true)
        .then(res => {
          setIsLoading(false);
          if(res === 'Unauthenticated.' || res === "E_UNAUTHORIZED_ACCESS: Unauthorized access"){
              HandleLogout(navigate);
          };
          if(res?.status === "success"){
            setRefreshKey(prev => prev + 1);
            setCreateResultModal(false);
          };
        });
    };



  return (
    <>
       
            <div className="card mb-3 mb-lg-5">
                <div className="card-header">
                    <div className="row align-items-center">
                        <div className="col-sm-5">
                            <h4 className="card-title mb-2 mb-sm-0">
                                {type} - Result
                            </h4>
                        </div>
                        
                            <div className="col-sm-7">
                            {
                                type === "MM 2D" &&
                                <button className='btn btn-outline-primary float-end' onClick={()=> {
                                    setCreateResultModal(true);
                                    setDigit("");
                                    setLotteryTime("");
                                }}>{type} Create Result</button>
                            }
                            {
                                type === "Gold 2D" &&
                                <button className='btn btn-outline-primary float-end' onClick={()=> {
                                    setCreateGoldResultModal(true);
                                    setDigit("");
                                    setLotteryTime("");
                                }}>{type} Create Result</button>
                            }
                            {
                                type === "Dubai 2D" &&
                                <button className='btn btn-outline-primary float-end' onClick={()=> {
                                    setCreateDubaiResultModal(true);
                                    setDigit("");
                                    setLotteryTime("");
                                }}>{type} Create Result</button>
                            }
                            {
                                type === "Malay 2D" &&
                                <button className='btn btn-outline-primary float-end' onClick={()=> {
                                    setCreateMalayResultModal(true);
                                    setDigit("");
                                    setLotteryTime("");
                                }}>{type} Create Result</button>
                            }
                            {
                                type === "MM 3D" &&
                                <button className='btn btn-outline-primary float-end' onClick={()=> {
                                    setThreedCreateResultModal(true);
                                    setDigit("");
                                    setVotes("");
                                }}>{type} Create Result</button>
                            }
                            {
                                type === "BTC 2D" &&
                                <button className='btn btn-outline-primary float-end' onClick={()=> {
                                    setCreateBTCResultModal(true);
                                    setDigit("");
                                    setVotes("");
                                }}>{type} Create Result</button>
                            }
                            {
                                type === "Gold 3D" &&
                                <button className='btn btn-outline-primary float-end' onClick={()=> {
                                    setThreedCreateGoldResultModal(true);
                                    setDigit("");
                                    setVotes("");
                                }}>{type} Create Result</button>
                            }
                            {
                                type === "Dubai 3D" &&
                                <button className='btn btn-outline-primary float-end' onClick={()=> {
                                    setThreedCreateDubaiResultModal(true);
                                    setDigit("");
                                    setVotes("");
                                }}>{type} Create Result</button>
                            }
                        
                        </div> 
                         
                       
                    </div>
                    <div className='d-flex justify-content-end mt-2'>
                    <DateRangePickerComponent startDate={startDate} endDate={endDate}  onChange={handleChangeValueDateRangePicker}/>
                    </div>
                </div>
                <div className="card-body">
                    <div className="row">
                        <div className="col-sm-12">
                            <TableScroll>
                                <div>
                                    <table className="table text-white w-100 table-bordered table-striped">
                                        <thead>
                                            <tr style={{backgroundColor: 'rgb(70 70 227)'}}>
                                                <th className="p-2 text-center text-light">No</th>
                                                <th className="p-2 text-center text-light">Digit</th>
                                                {
                                                    (type === "MM 3D" || type === "Dubai 3D") &&
                                                    <th className="p-2 text-center text-light">Votes</th>
                                                }
                                                <th className="p-2 text-center text-light">Type</th>
                                                <th className="p-2 text-center text-light">Time</th>
                                                <th className="p-2 text-center text-light">Status</th>
                                                <th className="p-2 text-center text-light">Date</th>
                                                {
                                                    (type === "MM 3D" || type === "Dubai 3D") &&
                                                    <th className="p-2 text-center text-light">Action</th>
                                                }
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {isLoading && <TableLoading col={8} />}
                                            {
                                                result.length > 0 ? 
                                                result.map((res,i) => 
                                                <tr style={{verticalAlign: 'middle'}} key={res?.id}>
                                                    <td className="p-2 text-center text-dark">{i + 1}</td>
                                                    {
                                                        (type === "MM 3D" || type === "Dubai 3D") ?
                                                        <td className="p-2 text-center text-dark">{res?.number}</td>:

                                                        res?.status === "Pending" ?
                                                        <td className="p-2 text-center text-dark text-decoration-underline">  
                                                            <EasyEdit
                                                                type="number"
                                                                onSave={ (value) => editTwoDigitHandler( value ,res?.id)}
                                                                saveButtonLabel={<i className="bi bi-check-lg btn btn-success p-2 py-1" style={{fontSize: '10px'}}></i>}
                                                                cancelButtonLabel={<i className="bi bi-x-lg btn btn-danger p-2 py-1" style={{fontSize: '10px'}}></i>}
                                                                id={res?.id}
                                                                attributes={{ id: res?.id}}
                                                                value={res?.number}
                                                            />
                                                        </td>:
                                                        <td className="p-2 text-center text-dark">{res?.number}</td>
                                                    }
                                                    {
                                                        (type === "MM 3D" || type === "Dubai 3D") &&
                                                        <td className="p-2 text-center text-dark">{res?.votes?.toString()}</td>
                                                    }
                                                    <td className="p-2 text-center text-dark">{betType(res?.type)}</td>
                                                    <td className="p-2 text-center text-dark">
                                                        {
                                                            type === "MM 2D" &&
                                                            <>
                                                                {res?.time === '1' && <span className='badge bg-info'>12:01 PM</span>}
                                                                {res?.time === '2' && <span className='badge bg-danger'>04:30 PM</span>}
                                                            </>
                                                        }
                                                        {
                                                            type === "MM 3D" &&
                                                            <>
                                                                {res?.time === '1' && <span className='badge bg-info'>၁၆ ရက်</span>}
                                                                {res?.time === '2' && <span className='badge bg-info'>၁ ရက်</span>}
                                                            </>
                                                        }
                                                        {
                                                           ( type === "Gold 2D" || type === "BTC 2D") &&
                                                            <>
                                                                {res?.time === '1' && <span className='badge bg-info'>09:30 AM</span>}
                                                                {res?.time === '2' && <span className='badge bg-danger'>12:00 PM</span>}
                                                                {res?.time === '3' && <span className='badge bg-primary'>02:00 PM</span>}
                                                                {res?.time === '4' && <span className='badge bg-warning'>04:30 PM</span>}
                                                                {res?.time === '5' && <span className='badge bg-success'>08:00 PM</span>}
                                                            </>
                                                        }
                                                        {
                                                            type === "Dubai 2D" &&
                                                            <>
                                                                {res?.time === '1' && <span className='badge bg-info'>11:00 AM</span>}
                                                                {res?.time === '2' && <span className='badge bg-danger'>01:00 PM</span>}
                                                                {res?.time === '3' && <span className='badge bg-primary'>03:00 PM</span>}
                                                                {res?.time === '4' && <span className='badge bg-warning'>05:00 PM</span>}
                                                                {res?.time === '5' && <span className='badge bg-secondary'>07:00 PM</span>}
                                                                {res?.time === '6' && <span className='badge bg-success'>09:00 PM</span>}
                                                            </>
                                                        }
                                                        {
                                                            type === "Malay 2D" &&
                                                            <>
                                                                {res?.time === '1' && <span className='badge bg-info'>10:00 AM</span>}
                                                                {res?.time === '2' && <span className='badge bg-danger'>12:00 PM</span>}
                                                                {res?.time === '3' && <span className='badge bg-primary'>02:00 PM</span>}
                                                                {res?.time === '4' && <span className='badge bg-warning'>04:00 PM</span>}
                                                                {res?.time === '5' && <span className='badge bg-secondary'>06:00 PM</span>}
                                                                {res?.time === '6' && <span className='badge bg-success'>08:00 PM</span>}
                                                            </>
                                                        }
                                                        {
                                                            type === "Dubai 3D" &&
                                                            <>
                                                                {res?.time === '1' && <span className='badge bg-info'>09:00 PM</span>}
                                                            </>
                                                        }
                                                        
                                                    </td>
                                                    <td className="p-2 text-center text-dark">
                                                        {
                                                           res?.status === "Pending" ?
                                                           <>
                                                               <button className='btn btn-success btn-sm px-2 py-1 me-1' onClick={()=> statusHandler(res?.id,"Approved")} style={{fontSize: '13px'}}>Approve</button>
                                                               <button className='btn btn-danger btn-sm px-2 py-1 ms-1' onClick={()=> statusHandler(res?.id,"Rejected")} style={{fontSize: '13px'}}>Reject</button>
                                                           </>
                                                           :
                                                           <span className={`badge bg-${res?.status === "Approved"? 'success': 'danger'} `}>{res?.status}</span>
                                                        }
                                                    </td>
                                                    <td className="p-2 text-center text-dark">{momentDateFormat(res?.updated_at)}</td>
                                                    {
                                                        (type === "MM 3D" || type === "Dubai 3D") &&
                                                        <td className="p-2 text-center text-dark">
                                                        {
                                                            res?.status === "Pending"?
                                                            <button className='btn btn-primary btn-sm px-2 py-1 me-1' onClick={()=> {
                                                                setThreedEditResultModal(true);
                                                                setEditData(res);
                                                            }} style={{fontSize: '13px'}}>Edit</button> :
                                                            '--'
                                                        }
                                                            
                                                        </td>
                                                    }
                                                </tr>
                                                )
                                                :
                                                <tr>
                                                    <td className='text-center' colSpan={8}>No found data</td>
                                                </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </TableScroll>
                        </div>
                    </div>
                </div>
            </div>
       
        <ToastContainer />
        {
            createResultModal && (
                <ModalBox
                    title="Create 2D Result"
                    onClose={() => setCreateResultModal(false)}
                    onSubmit={createResultHandler}
                    isLoading={isLoading}
                >
                    <Form>
                        <FormGroup>
                            <Label for="">Two Digit Number</Label>
                            <Input type="number"  value={digit} onChange={(e)=> setDigit(e.target.value)}/>
                        </FormGroup>
                        <FormGroup>
                            <Label for="">Lottery Time</Label>
                            <select className='form-control' value={lotteryTime} onChange={(e)=> setLotteryTime(e.target.value)}>
                                <option value="">-- အချိန်ရွေးပါ --</option>
                                <option value="1">12:01 PM</option>
                                <option value="2">04:30 PM</option>
                            </select>
                        </FormGroup>
                    </Form>
                </ModalBox>
            )
        }
        {
            createGoldResultModal && (
                <ModalBox
                    title="Create Gold 2D Result"
                    onClose={() => setCreateGoldResultModal(false)}
                    onSubmit={createGoldResultHandler}
                    isLoading={isLoading}
                >
                    <Form>
                        <FormGroup>
                            <Label for="">Two Digit Number</Label>
                            <Input type="number"  value={digit} onChange={(e)=> setDigit(e.target.value)}/>
                        </FormGroup>
                        <FormGroup>
                            <Label for="">Lottery Time</Label>
                            <select className='form-control' value={lotteryTime} onChange={(e)=> setLotteryTime(e.target.value)}>
                                <option value="">-- အချိန်ရွေးပါ --</option>
                                <option value="1">09:30 AM</option>
                                <option value="2">12:00 PM</option>
                                <option value="3">02:00 PM</option>
                                <option value="4">04:30 PM</option>
                                <option value="5">08:00 PM</option>
                            </select>
                        </FormGroup>
                    </Form>
                </ModalBox>
            )
        }

        {
            createBTCResultModal && (
                <ModalBox
                    title="Create BTC 2D Result"
                    onClose={() => setCreateBTCResultModal(false)}
                    onSubmit={createBTCResultHandler}
                    isLoading={isLoading}
                >
                    <Form>
                        <FormGroup>
                            <Label for="">Two Digit Number</Label>
                            <Input type="number"  value={digit} onChange={(e)=> setDigit(e.target.value)}/>
                        </FormGroup>
                        <FormGroup>
                            <Label for="">Lottery Time</Label>
                            <select className='form-control' value={lotteryTime} onChange={(e)=> setLotteryTime(e.target.value)}>
                                <option value="">-- အချိန်ရွေးပါ --</option>
                                <option value="1">09:30 AM</option>
                                <option value="2">12:00 PM</option>
                                <option value="3">02:00 PM</option>
                                <option value="4">04:30 PM</option>
                                <option value="5">08:00 PM</option>
                            </select>
                        </FormGroup>
                    </Form>
                </ModalBox>
            )
        }
        {
            createDubaiResultModal && (
                <ModalBox
                    title="Create Dubai 2D Result"
                    onClose={() => setCreateDubaiResultModal(false)}
                    onSubmit={createDubaiResultHandler}
                    isLoading={isLoading}
                >
                    <Form>
                        <FormGroup>
                            <Label for="">Two Digit Number</Label>
                            <Input type="number"  value={digit} onChange={(e)=> setDigit(e.target.value)}/>
                        </FormGroup>
                        <FormGroup>
                            <Label for="">Lottery Time</Label>
                            <select className='form-control' value={lotteryTime} onChange={(e)=> setLotteryTime(e.target.value)}>
                                <option value="">-- အချိန်ရွေးပါ --</option>
                                <option value="1">11:00 AM</option>
                                <option value="2">01:00 PM</option>
                                <option value="3">03:00 PM</option>
                                <option value="4">05:00 PM</option>
                                <option value="5">07:00 PM</option>
                                <option value="6">09:00 PM</option>
                            </select>
                        </FormGroup>
                    </Form>
                </ModalBox>
            )
        }
        {
            createMalayResultModal && (
                <ModalBox
                    title="Create Malay 2D Result"
                    onClose={() => setCreateMalayResultModal(false)}
                    onSubmit={createMalayResultHandler}
                    isLoading={isLoading}
                >
                    <Form>
                        <FormGroup>
                            <Label for="">Two Digit Number</Label>
                            <Input type="number"  value={digit} onChange={(e)=> setDigit(e.target.value)}/>
                        </FormGroup>
                        <FormGroup>
                            <Label for="">Lottery Time</Label>
                            <select className='form-control' value={lotteryTime} onChange={(e)=> setLotteryTime(e.target.value)}>
                                <option value="">-- အချိန်ရွေးပါ --</option>
                                <option value="1">10:00 AM</option>
                                <option value="2">12:00 PM</option>
                                <option value="3">02:00 PM</option>
                                <option value="4">04:00 PM</option>
                                <option value="5">06:00 PM</option>
                                <option value="6">08:00 PM</option>
                            </select>
                        </FormGroup>
                    </Form>
                </ModalBox>
            )
        }
        {
            threedCreateResultModal && (
                <ModalBox
                    title="Create 3D Result"
                    onClose={() => setThreedCreateResultModal(false)}
                    onSubmit={threedCreateResultHandler}
                    isLoading={isLoading}
                >
                    <Form>
                        <FormGroup>
                            <Label for="">Three Digit Number</Label>
                            <Input type="number"  value={digit} onChange={(e)=> setDigit(e.target.value)}/>
                        </FormGroup>
                        <FormGroup>
                            <Label for="">Votes</Label>
                            <Input type="text"  value={votes} onChange={(e)=> setVotes(e.target.value)}/>
                        </FormGroup>
                        <FormGroup>
                            <Label for="">Lottery Time</Label>
                            <select className='form-control' value={lotteryTime} onChange={(e)=> setLotteryTime(e.target.value)}>
                                <option value="">-- အချိန်ရွေးပါ --</option>
                                <option value="1">၁၆ ရက်</option>
                                <option value="2">၁ ရက်</option>
                            </select>
                        </FormGroup>
                    </Form>
                </ModalBox>
            )
        }
        {
            threedEditResultModal && (
                <ModalBox
                    title="Edit 3D Result"
                    onClose={() => setThreedEditResultModal(false)}
                    onSubmit={threedEditResultHandler}
                    isLoading={isLoading}
                >
                    <Form>
                        <FormGroup>
                            <Label for="">Three Digit Number</Label>
                            <Input type="number"  value={digit} onChange={(e)=> setDigit(e.target.value)}/>
                        </FormGroup>
                        <FormGroup>
                            <Label for="">Votes</Label>
                            <Input type="text"  value={votes} onChange={(e)=> setVotes(e.target.value)}/>
                        </FormGroup>
                    </Form>
                </ModalBox>
            )
        }
        {
            threedCreateDubaiResultModal && (
                <ModalBox
                    title="Create Dubai 3D Result"
                    onClose={() => setThreedCreateDubaiResultModal(false)}
                    onSubmit={threedCreateDubaiResultHandler}
                    isLoading={isLoading}
                >
                    <Form>
                        <FormGroup>
                            <Label for="">Three Digit Number</Label>
                            <Input type="number"  value={digit} onChange={(e)=> setDigit(e.target.value)}/>
                        </FormGroup>
                        <FormGroup>
                            <Label for="">Votes</Label>
                            <Input type="text"  value={votes} onChange={(e)=> setVotes(e.target.value)}/>
                        </FormGroup>
                    </Form>
                </ModalBox>
            )
        }

{
            threedCreateGoldResultModal && (
                <ModalBox
                    title="Create Gold 3D Result"
                    onClose={() => setThreedCreateGoldResultModal(false)}
                    onSubmit={threedCreateGoldResultHandler}
                    isLoading={isLoading}
                >
                    <Form>
                        <FormGroup>
                            <Label for="">Three Digit Number</Label>
                            <Input type="number"  value={digit} onChange={(e)=> setDigit(e.target.value)}/>
                        </FormGroup>
                        <FormGroup>
                            <Label for="">Votes</Label>
                            <Input type="text"  value={votes} onChange={(e)=> setVotes(e.target.value)}/>
                        </FormGroup>
                    </Form>
                </ModalBox>
            )
        }
    </>
  )
}

export default Result
