import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { decodeData } from "../utils/Helper";
import { useDispatch, useSelector } from "react-redux";
import { userIdHandler } from "../store/slices/userIdSlice";
const sidebarLogo = require("../assets/images/logo/dashboard_light_logo.png");

const Sidebar = () => {
  const url = window.location.pathname;
  const params = useParams();
  const { user_info } = useSelector((state: any) => state.userInfo);
  
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const start_date_v2 = params?.start_date && `${params.start_date}`;
  const end_date_v2 =
    params?.end_date &&
    `${moment(params.end_date).add(0, "days").format("YYYY-MM-DD")} 10:28`;
  const activeLink = `/stock-manage/member/betting-list/${params.id}/${params.status}/${start_date_v2}/${end_date_v2}`;
  const replaceActiveLink = activeLink.split(" ").join("%20");

  const reportActivLink = `/report/win-lose-detail/${params.id}/${params.status}/${params.start_date}/${params.end_date}`;
  const replaceReportActiveLink = reportActivLink.split(" ").join("%20");

  const reportAuserActivLink = `/report/win-lose-detail-a-user/${params.id}/${params.status}/${params.start_date}/${params.end_date}`;
  const replaceReportAuserActiveLink = reportAuserActivLink
    .split(" ")
    .join("%20");

  // useEffect(()=>{
  //     const getAuth = localStorage.getItem('a_u');
  //     let decode = getAuth && decodeData(getAuth);
  //     if(decode){
  //         setUserInfo(decode)
  //     };
  // },[]);

  const bodyClass = document.querySelector(
    ".navbar-vertical-aside-closed-mode "
  );
  const logoutHandler = () => {
    const token = localStorage.getItem("one_star_a");
    if (token) {
      const delay = setTimeout(() => {
        localStorage.removeItem("one_star_a");
        navigate("/login");
        window.location.reload();
      }, 1000);

      return () => {
        clearTimeout(delay);
      };
    }
  };

  const renderNavBar = () => {
    if (user_info === "undefined") return <></>;
  };

  return (
    <>
      {typeof user_info !== "undefined" ? (
        <aside
          className="js-navbar-vertical-aside navbar navbar-vertical-aside navbar-vertical navbar-vertical-fixed navbar-expand-xl navbar-bordered"
          style={{ backgroundColor: "rgb(1 1 60)" }}
        >
          <div className="navbar-vertical-container">
            <div className="navbar-vertical-footer-offset">
              {/* Logo */}
              <Link
                className="navbar-brand justify-content-center"
                to="/"
                aria-label="Front"
              >
                <img
                  className="rounded-circle"
                  src={sidebarLogo}
                  alt="Logo"
                  style={{
                    width: "50px",
                    marginTop: "15px",
                    border: "2px solid #FFF",
                    boxShadow: "0 5px 10px 0 rgba(43, 43, 43, .2)",
                  }}
                />
              </Link>
              {/* End Logo */}

              {/* Navbar Vertical Toggle */}
              <button
                type="button"
                className="js-navbar-vertical-aside-toggle-invoker navbar-aside-toggler"
              >
                <i
                  className="bi-arrow-bar-left navbar-toggler-short-align"
                  data-bs-template='<div className="tooltip d-none d-md-block" role="tooltip"><div className="arrow"></div><div className="tooltip-inner"></div></div>'
                  data-bs-toggle="tooltip"
                  data-bs-placement="right"
                  title="Collapse"
                ></i>
                <i
                  className="bi-arrow-bar-right navbar-toggler-full-align"
                  data-bs-template='<div className="tooltip d-none d-md-block" role="tooltip"><div className="arrow"></div><div className="tooltip-inner"></div></div>'
                  data-bs-toggle="tooltip"
                  data-bs-placement="right"
                  title="Expand"
                ></i>
              </button>
              {/* End Navbar Vertical Toggle */}

              <div className="navbar-vertical-content">
                {user_info.role_id === 3 ? (
                  <div
                    id="navbarVerticalMenu"
                    className="nav nav-pills nav-vertical card-navbar-nav"
                  >
                    <div className="nav-item">
                      <Link
                        className={`nav-link ${url === "/" && "active"}`}
                        to="/"
                        data-placement="left"
                      >
                        <i className="bi-house-door nav-icon"></i>
                        <span className="nav-link-title">ပင်မစာမျက်နှာ</span>
                      </Link>
                    </div>

                    <div className="nav-item">
                      <Link
                        className={`nav-link ${url === "/setting" && "active"}`}
                        to="/setting"
                        data-placement="left"
                      >
                        <i className="bi bi-gear-fill nav-icon"></i>
                        <span className="nav-link-title">ဆက်တင်များ</span>
                      </Link>
                    </div>

                    

                    <div className="nav-item">
                      <Link
                        className="nav-link dropdown-toggle"
                        to="#navbarVerticalMenuDashboards_users"
                        role="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#navbarVerticalMenuDashboards_users"
                        aria-expanded="false"
                        aria-controls="navbarVerticalMenuDashboards_users"
                      >
                        <i className="bi bi-people-fill nav-icon"></i>
                        <span className="nav-link-title">မိတ်ဖက်များ</span>
                      </Link>

                      <div
                        id="navbarVerticalMenuDashboards_users"
                        className={`nav-collapse collapse
                               ${
                                 (url === "/users/senior" ||
                                   url === `/users/master` ||
                                   url === `/users/agent` ||
                                   url === "/users/user" ||
                                   url === "/users/create-senior") &&
                                 "show"
                               } 
                            `}
                        data-bs-parent="#navbarVerticalMenuAccount"
                      >
                        <Link
                          className={`nav-link ${
                            (url === "/users/senior" ||
                              url === "/users/create-senior") &&
                            "active"
                          }`}
                          to="/users/senior"
                        >
                          စီနီယာများ
                        </Link>
                        <Link
                          className={`nav-link ${
                            url === "/users/master" && "active"
                          }`}
                          to="/users/master"
                        >
                          မာစတာများ
                        </Link>
                        <Link
                          className={`nav-link ${
                            url === "/users/agent" && "active"
                          }`}
                          to="/users/agent"
                        >
                          အေးဂျင့်များ
                        </Link>
                        <Link
                          className={`nav-link ${
                            url === "/users/user" && "active"
                          }`}
                          to="/users/user"
                        >
                          ယူဆာများ
                        </Link>
                      </div>
                    </div>

                    <div className="nav-item">
                      <Link
                        className="nav-link dropdown-toggle"
                        to="#navbarVerticalMenuDashboards_result"
                        role="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#navbarVerticalMenuDashboards_result"
                        aria-expanded="false"
                        aria-controls="navbarVerticalMenuDashboards_result"
                      >
                        <i className="bi bi-card-checklist nav-icon"></i>
                        <span className="nav-link-title">ထွက်ဂဏာန်းများ</span>
                      </Link>

                      <div
                        id="navbarVerticalMenuDashboards_result"
                        className={`nav-collapse collapse
                               ${
                                 (url === "/result/2d" ||
                                   url === `/result/gold2d` ||
                                   url === `/result/dubai2d` ||
                                   url === `/result/malay2d` ||
                                   url === `/result/3d` ||
                                   url === `/result/btc2d` ||
                                   url === `/result/gold3d` ||
                                   url === `/result/dubai3d`) &&
                                 "show"
                               } 
                            `}
                        data-bs-parent="#navbarVerticalMenuAccount"
                      >
                        <Link
                          className={`nav-link ${
                            url === "/result/2d" && "active"
                          }`}
                          to="/result/2d"
                        >
                          မြန်မာ 2D
                        </Link>
                        <Link
                          className={`nav-link ${
                            url === "/result/gold2d" && "active"
                          }`}
                          to="/result/gold2d"
                        >
                          Gold 2D
                        </Link>
                        <Link
                          className={`nav-link ${
                            url === "/result/dubai2d" && "active"
                          }`}
                          to="/result/dubai2d"
                        >
                          Dubai 2D
                        </Link>
                        <Link
                          className={`nav-link ${
                            url === "/result/malay2d" && "active"
                          }`}
                          to="/result/malay2d"
                        >
                          Malay 2D
                        </Link>
                        <Link
                          className={`nav-link ${
                            url === "/result/btc2d" && "active"
                          }`}
                          to="/result/btc2d"
                        >
                          BTC 2D
                        </Link>
                        <Link
                          className={`nav-link ${
                            url === "/result/3d" && "active"
                          }`}
                          to="/result/3d"
                        >
                          မြန်မာ 3D
                        </Link>
                        <Link
                          className={`nav-link ${
                            url === "/result/dubai3d" && "active"
                          }`}
                          to="/result/dubai3d"
                        >
                          Dubai 3D
                        </Link>
                        
                        <Link
                          className={`nav-link ${
                            url === "/result/gold3d" && "active"
                          }`}
                          to="/result/gold3d"
                        >
                           Gold  3D
                        </Link>
                      </div>
                    </div>

                    <div className="nav-item">
                      <Link
                        className="nav-link dropdown-toggle"
                        to="#navbarVerticalMenuDashboards_winner"
                        role="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#navbarVerticalMenuDashboards_winner"
                        aria-expanded="false"
                        aria-controls="navbarVerticalMenuDashboards_winner"
                      >
                        <i className="bi bi-card-checklist nav-icon"></i>
                        <span className="nav-link-title">ထီပေါက်သူများ</span>
                      </Link>

                      <div
                        id="navbarVerticalMenuDashboards_winner"
                        className={`nav-collapse collapse
                               ${
                                 (url === "/winner/2d" ||
                                   url === `/winner/gold2d` ||
                                   url === `/winner/dubai2d` ||
                                   url === `/winner/malay2d` ||
                                   url === `/winner/3d` ||
                                   url === `/winner/gold3d` ||
                                   url === `/winner/btc2d` ||
                                   url === `/winner/dubai3d`) &&
                                 "show"
                               } 
                            `}
                        data-bs-parent="#navbarVerticalMenuAccount"
                      >
                        <Link
                          className={`nav-link ${
                            url === "/winner/2d" && "active"
                          }`}
                          to="/winner/2d"
                        >
                          2D ပေါက်သူများ
                        </Link>
                        <Link
                          className={`nav-link ${
                            url === "/winner/gold2d" && "active"
                          }`}
                          to="/winner/gold2d"
                        >
                          Gold 2D ပေါက်သူများ
                        </Link>
                        <Link
                          className={`nav-link ${
                            url === "/winner/dubai2d" && "active"
                          }`}
                          to="/winner/dubai2d"
                        >
                          Dubai 2D ပေါက်သူများ
                        </Link>
                        <Link
                          className={`nav-link ${
                            url === "/winner/malay2d" && "active"
                          }`}
                          to="/winner/malay2d"
                        >
                          Malay 2D ပေါက်သူများ
                        </Link>
                        <Link
                          className={`nav-link ${
                            url === "/winner/btc2d" && "active"
                          }`}
                          to="/winner/btc2d"
                        >
                          BTC 2D ပေါက်သူများ
                        </Link>
                        <Link
                          className={`nav-link ${
                            url === "/winner/3d" && "active"
                          }`}
                          to="/winner/3d"
                        >
                          3D ပေါက်သူများ
                        </Link>
                        <Link
                          className={`nav-link ${
                            url === "/winner/dubai3d" && "active"
                          }`}
                          to="/winner/dubai3d"
                        >
                          Dubai 3D ပေါက်သူများ
                        </Link>
                       
                        <Link
                          className={`nav-link ${
                            url === "/winner/gold3d" && "active"
                          }`}
                          to="/winner/gold3d"
                        >
                          Gold 3D ပေါက်သူများ
                        </Link>
                      </div>
                    </div>

                    <div className="nav-item">
                      <Link
                        className="nav-link dropdown-toggle"
                        to="#navbarVerticalMenuDashboards_report"
                        role="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#navbarVerticalMenuDashboards_report"
                        aria-expanded="false"
                        aria-controls="navbarVerticalMenuDashboards_report"
                      >
                        <i className="bi bi-card-checklist nav-icon"></i>
                        <span className="nav-link-title">
                          မှတ်တမ်း (အနိုင်/အရှုံး)
                        </span>
                      </Link>

                      <div
                        id="navbarVerticalMenuDashboards_report"
                        className={`nav-collapse collapse
                               ${
                                 (url === "/report/2d" ||
                                   url === `/report/gold2d` ||
                                   url === `/report/dubai2d` ||
                                   url === `/report/malay2d` ||
                                   url === `/report/3d` ||
                                   url === `/report/btc2d` ||
                                   url === `/report/gold3d` ||
                                   url === `/report/dubai3d`) &&
                                 "show"
                               } 
                            `}
                        data-bs-parent="#navbarVerticalMenuAccount"
                      >
                        <Link
                          className={`nav-link ${
                            url === "/report/2d" && "active"
                          }`}
                          to="/report/2d"
                        >
                          2D (အနိုင်/အရှုံး)
                        </Link>
                        <Link
                          className={`nav-link ${
                            url === "/report/gold2d" && "active"
                          }`}
                          to="/report/gold2d"
                        >
                          Gold (အနိုင်/အရှုံး)
                        </Link>
                        <Link
                          className={`nav-link ${
                            url === "/report/dubai2d" && "active"
                          }`}
                          to="/report/dubai2d"
                        >
                          Dubai (အနိုင်/အရှုံး)
                        </Link>
                        <Link
                          className={`nav-link ${
                            url === "/report/malay2d" && "active"
                          }`}
                          to="/report/malay2d"
                        >
                          Malay (အနိုင်/အရှုံး)
                        </Link>
                        <Link
                          className={`nav-link ${
                            url === "/report/btc2d" && "active"
                          }`}
                          to="/report/btc2d"
                        >
                          BTC 2D (အနိုင်/အရှုံး)
                        </Link>
                        <Link
                          className={`nav-link ${
                            url === "/report/3d" && "active"
                          }`}
                          to="/report/3d"
                        >
                          3D (အနိုင်/အရှုံး)
                        </Link>
                        <Link
                          className={`nav-link ${
                            url === "/report/dubai3d" && "active"
                          }`}
                          to="/report/dubai3d"
                        >
                          Dubai 3D (အနိုင်/အရှုံး)
                        </Link>
                        
                        <Link
                          className={`nav-link ${
                            url === "/report/gold3d" && "active"
                          }`}
                          to="/report/gold3d"
                        >
                          Gold 3D (အနိုင်/အရှုံး)
                        </Link>
                      </div>
                    </div>

                    <div className="nav-item">
                      <Link
                        className="nav-link dropdown-toggle"
                        to="#navbarVerticalMenuDashboards_lossprofit"
                        role="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#navbarVerticalMenuDashboards_lossprofit"
                        aria-expanded="false"
                        aria-controls="navbarVerticalMenuDashboards_lossprofit"
                      >
                        <i className="bi bi-card-checklist nav-icon"></i>
                        <span className="nav-link-title">
                          အရှုံး/အမြတ်စာရင်းများ
                        </span>
                      </Link>

                      <div
                        id="navbarVerticalMenuDashboards_lossprofit"
                        className={`nav-collapse collapse
                               ${url === "/profit/senior" && "show"} 
                            `}
                        data-bs-parent="#navbarVerticalMenuAccount"
                      >
                        <Link
                          className={`nav-link ${
                            url === "/profit/senior" && "active"
                          }`}
                          to="/profit/senior"
                        >
                          Senior အရှုံး/အမြတ်
                        </Link>
                      </div>
                    </div>

                    <div className="nav-item">
                      <Link
                        className="nav-link dropdown-toggle"
                        to="#navbarVerticalMenuDashboards_bets"
                        role="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#navbarVerticalMenuDashboards_bets"
                        aria-expanded="false"
                        aria-controls="navbarVerticalMenuDashboards_bets"
                      >
                        <i className="bi bi-card-checklist nav-icon"></i>
                        <span className="nav-link-title">
                          ထိုးထားသောစာရင်းများ
                        </span>
                      </Link>

                      <div
                        id="navbarVerticalMenuDashboards_bets"
                        className={`nav-collapse collapse
                               ${
                                 (url === "/two_lucky_draws/2d" ||
                                   url === "/two_lucky_draws/gold2d" ||
                                   url === "/two_lucky_draws/dubai2d" ||
                                   url === "/two_lucky_draws/malay2d" ||
                                   url === `/three_lucky_draws/3d` ||
                                   url === `/three_lucky_draws/dubai3d`) &&
                                 "show"
                               } 
                            `}
                        data-bs-parent="#navbarVerticalMenuAccount"
                      >
                        <Link
                          className={`nav-link ${
                            url === "/two_lucky_draws/2d" && "active"
                          }`}
                          to="/two_lucky_draws/2d"
                        >
                          မြန်မာ 2D စာရင်း
                        </Link>
                        <Link
                          className={`nav-link ${
                            url === "/two_lucky_draws/gold2d" && "active"
                          }`}
                          to="/two_lucky_draws/gold2d"
                        >
                          Gold 2D စာရင်း
                        </Link>
                        <Link
                          className={`nav-link ${
                            url === "/two_lucky_draws/dubai2d" && "active"
                          }`}
                          to="/two_lucky_draws/dubai2d"
                        >
                          Dubai 2D စာရင်း
                        </Link>
                        <Link
                          className={`nav-link ${
                            url === "/two_lucky_draws/malay2d" && "active"
                          }`}
                          to="/two_lucky_draws/malay2d"
                        >
                          Malay 2D စာရင်း
                        </Link>
                        <Link
                          className={`nav-link ${
                            url === "/two_lucky_draws/btc2d" && "active"
                          }`}
                          to="/two_lucky_draws/btc2d"
                        >
                          BTC 2D စာရင်း
                        </Link>
                        <Link
                          className={`nav-link ${
                            url === "/three_lucky_draws/3d" && "active"
                          }`}
                          to="/three_lucky_draws/3d"
                        >
                          မြန်မာ 3D စာရင်း
                        </Link>
                        <Link
                          className={`nav-link ${
                            url === "/three_lucky_draws/dubai3d" && "active"
                          }`}
                          to="/three_lucky_draws/dubai3d"
                        >
                          Dubai 3D စာရင်း
                        </Link>
                        <Link
                          className={`nav-link ${
                            url === "/three_lucky_draws/gold3d" && "active"
                          }`}
                          to="/three_lucky_draws/gold3d"
                        >
                          Gold 3D စာရင်း
                        </Link>
                      </div>
                    </div>

                    <div className="nav-item">
                      <Link
                        className="nav-link dropdown-toggle"
                        to="#navbarVerticalMenuDashboards_wallet_trans"
                        role="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#navbarVerticalMenuDashboards_wallet_trans"
                        aria-expanded="false"
                        aria-controls="navbarVerticalMenuDashboards_wallet_trans"
                      >
                        <i className="bi bi-wallet2 nav-icon"></i>
                        <span className="nav-link-title">ထည့်ထုတ်မှတ်တမ်း</span>
                      </Link>

                      <div
                        id="navbarVerticalMenuDashboards_wallet_trans"
                        className={`nav-collapse collapse
                               ${
                                 (url === "/wallet-trans/seniors-trans" ||
                                   url === `/wallet-trans/masters-trans` ||
                                   url === `/wallet-trans/agents-trans` ||
                                   url === "/wallet-trans/users-trans") &&
                                 "show"
                               } 
                            `}
                        data-bs-parent="#navbarVerticalMenuAccount"
                      >
                        <Link
                          className={`nav-link ${
                            url === "/wallet-trans/seniors-trans" && "active"
                          }`}
                          to="/wallet-trans/seniors-trans"
                        >
                          စီနီယာ ထည့်ထုတ်
                        </Link>
                        <Link
                          className={`nav-link ${
                            url === "/wallet-trans/masters-trans" && "active"
                          }`}
                          to="/wallet-trans/masters-trans"
                        >
                          မာစတာ ထည့်ထုတ်
                        </Link>
                        <Link
                          className={`nav-link ${
                            url === "/wallet-trans/agents-trans" && "active"
                          }`}
                          to="/wallet-trans/agents-trans"
                        >
                          အေးဂျင့် ထည့်ထုတ်
                        </Link>
                        <Link
                          className={`nav-link ${
                            url === "/wallet-trans/users-trans" && "active"
                          }`}
                          to="/wallet-trans/users-trans"
                        >
                          ယူဆာများ ထည့်ထုတ်
                        </Link>
                      </div>
                    </div>
                    <div className="nav-item">
                      <Link
                        className={`nav-link ${url === "/user-betting-transaction" && "active"}`}
                        to="/user-betting-transaction"
                        data-placement="left"
                      >
                        <i className="bi bi-gear-fill nav-icon"></i>
                        <span className="nav-link-title">ယူစာထိုးစရင်း</span>
                      </Link>
                    </div>
                    <div className="nav-item">
                      <Link
                        className={`nav-link ${
                          url === "/change-password" && "active"
                        }`}
                        to="/change-password"
                        data-placement="left"
                      >
                        <i className="bi-unlock nav-icon"></i>
                        <span className="nav-link-title">စကားဝှက်ပြောင်း</span>
                      </Link>
                    </div>

                    <span className="mt-4 dropdown-header">Logout</span>
                    <small className="bi-three-dots nav-subtitle-replacer"></small>
                    <div className="nav-item">
                      <Link
                        className="nav-link"
                        to=""
                        style={{ cursor: "pointer" }}
                        data-placement="left"
                        onClick={logoutHandler}
                      >
                        <i className="bi bi-box-arrow-right nav-icon"></i>
                        <span className="nav-link-title">ထွက်မည်</span>
                      </Link>
                    </div>
                  </div>
                ) : null}
                {user_info.role_id === 1 ? (
                  <div
                    id="navbarVerticalMenu"
                    className="nav nav-pills nav-vertical card-navbar-nav"
                  >
                    <div className="nav-item">
                      <Link
                        className={`nav-link ${url === "/" && "active"}`}
                        to="/"
                        data-placement="left"
                      >
                        <i className="bi-house-door nav-icon"></i>
                        <span className="nav-link-title">ပင်မစာမျက်နှာ</span>
                      </Link>
                    </div>
                    <div className="nav-item">
                      <Link
                        className={`nav-link ${url === "/setting" && "active"}`}
                        to="/setting"
                        data-placement="left"
                      >
                        <i className="bi bi-gear-fill nav-icon"></i>
                        <span className="nav-link-title">ဆက်တင်များ</span>
                      </Link>
                    </div>

                    <div className="nav-item">
                      <Link
                        className={`nav-link ${
                          (url === "admins" || url === "/users/create-admin") &&
                          "active"
                        }`}
                        to="/admins"
                        data-placement="left"
                      >
                        <i className="bi bi-gear-fill nav-icon"></i>
                        <span className="nav-link-title">App Admin များ </span>
                      </Link>
                    </div>

                    <div className="nav-item">
                      <Link
                        className="nav-link dropdown-toggle"
                        to="#navbarVerticalMenuDashboards_result"
                        role="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#navbarVerticalMenuDashboards_result"
                        aria-expanded="false"
                        aria-controls="navbarVerticalMenuDashboards_result"
                      >
                        <i className="bi bi-card-checklist nav-icon"></i>
                        <span className="nav-link-title">ထွက်ဂဏာန်းများ</span>
                      </Link>

                      <div
                        id="navbarVerticalMenuDashboards_result"
                        className={`nav-collapse collapse
                               ${
                                 (url === "/result/2d" ||
                                   url === `/result/gold2d` ||
                                   url === `/result/dubai2d` ||
                                   url === `/result/malay2d` ||
                                   url === `/result/3d` ||
                                   url === `/result/btc2d` ||
                                   url === `/result/gold3d` ||
                                   url === `/result/dubai3d`) &&
                                 "show"
                               } 
                            `}
                        data-bs-parent="#navbarVerticalMenuAccount"
                      >
                        <Link
                          className={`nav-link ${
                            url === "/result/2d" && "active"
                          }`}
                          to="/result/2d"
                        >
                          မြန်မာ 2D
                        </Link>
                        <Link
                          className={`nav-link ${
                            url === "/result/gold2d" && "active"
                          }`}
                          to="/result/gold2d"
                        >
                          Gold 2D
                        </Link>
                        <Link
                          className={`nav-link ${
                            url === "/result/dubai2d" && "active"
                          }`}
                          to="/result/dubai2d"
                        >
                          Dubai 2D
                        </Link>
                        <Link
                          className={`nav-link ${
                            url === "/result/malay2d" && "active"
                          }`}
                          to="/result/malay2d"
                        >
                          Malay 2D
                        </Link>
                        <Link
                          className={`nav-link ${
                            url === "/result/btc2d" && "active"
                          }`}
                          to="/result/btc2d"
                        >
                          BTC 2D
                        </Link>
                        <Link
                          className={`nav-link ${
                            url === "/result/3d" && "active"
                          }`}
                          to="/result/3d"
                        >
                          မြန်မာ 3D
                        </Link>
                        <Link
                          className={`nav-link ${
                            url === "/result/dubai3d" && "active"
                          }`}
                          to="/result/dubai3d"
                        >
                          Dubai 3D
                        </Link>
                       
                        <Link
                          className={`nav-link ${
                            url === "/result/gold3d" && "active"
                          }`}
                          to="/result/gold3d"
                        >
                           Gold  3D
                        </Link>
                      </div>
                    </div>
                    
                   

                    <div className="nav-item">
                      <Link
                        className={`nav-link ${
                          url === "/change-password" && "active"
                        }`}
                        to="/change-password"
                        data-placement="left"
                      >
                        <i className="bi-unlock nav-icon"></i>
                        <span className="nav-link-title">စကားဝှက်ပြောင်း</span>
                      </Link>
                    </div>

                    <span className="mt-4 dropdown-header">Logout</span>
                    <small className="bi-three-dots nav-subtitle-replacer"></small>
                     <div className="nav-item">
                      <Link
                        className={`nav-link ${url === "/setting" && "active"}`}
                        to="/setting"
                        data-placement="left"
                      >
                        <i className="bi bi-gear-fill nav-icon"></i>
                        <span className="nav-link-title">ဆက်တင်များ</span>
                      </Link>
                    </div>
                    <div className="nav-item">
                      <Link
                        className="nav-link"
                        to=""
                        style={{ cursor: "pointer" }}
                        data-placement="left"
                        onClick={logoutHandler}
                      >
                        <i className="bi bi-box-arrow-right nav-icon"></i>
                        <span className="nav-link-title">ထွက်မည်</span>
                      </Link>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </aside>
      ) : null}
    </>
  );
};

export default Sidebar;
