import { useEffect, useRef, useState } from 'react';
import Template from '../utils/Template'
import { HandleLogout, amountCommasSparated } from '../utils/Helper';
import { getMethodService, postMethodService } from '../services';
import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { DOWNLINE_COUNT_API, THREE_DIGITS_API, TWO_DIGITS_API, TWO_THREE_SETTING_API, TWO_THREE_SETTING_EDIT_API } from '../services/constants';
import { stateObjType } from '../types';
import { ToastContainer } from 'react-toastify';
import TableScroll from '../utils/TableScroll';
import TableLoading from '../utils/TableLoading';
import ModalBox from '../utils/ModalBox';
import { Form, FormGroup, Input, Label } from 'reactstrap';
import classes from '../assets/styles/Dashboard.module.css';
import { settingType } from '../services/helper';

const Dashboard = () => {
    const [isLoading,setIsLoading] = useState(false);
    const [data, setData] = useState<stateObjType>({});
    const [twoThreeSetting, setTwoThreeSetting] = useState<stateObjType>([]);
    const [twoDigits,setTwoDigits] = useState<any>([]);
    const [threeDigits,setThreeDigits] = useState<any>([]);
    const [twoThreedRefreshKey,setTwoThreedRefreshKey] = useState(0);
    const [twoDigitsRefreshKey,setTwoDigitsRefreshKey] = useState(0);
    const [threeDigitsRefreshKey,setThreeDigitsRefreshKey] = useState(0);
    const [odds,setOdds] = useState<any>(0);
    const [twit, setTwit] = useState<any>(0);
    const [minAmount,setMinAmount] = useState<any>(0);
    const [maxAmount,setMaxAmount] = useState<any>(0);
    const [showTwoThreedSettingEditModal, setShowTwoThreedSettingEditModal] = useState(false);
    const [editData,setEditData] = useState<any>(null);
    const [digitsId,setDigitsId] = useState<any>([]);
    const [threeDigitsId,setThreeDigitsId] = useState<any>([]);
    const [amount,setAmount] = useState("");
    const navigate = useNavigate();
    const [threedPage,setThreedPage] = useState<any>(1);
    const [twoLayoutType,setTwoLayoutType] = useState<string>("mm");
    const [threeLayoutType,setThreeLayoutType] = useState<string>("mm");

    const fetchDownlineCount = () => getMethodService(DOWNLINE_COUNT_API);
    const downlineCountQuery = useQuery({ queryKey: ['downlinecount'], queryFn: fetchDownlineCount, refetchOnWindowFocus: false});

    const fetchTwoThreeSetting = () => getMethodService(`${TWO_THREE_SETTING_API}?sortDirection=asc`);
    const twoThreeSettingQuery = useQuery({ queryKey: ['twoThreeSetting',twoThreedRefreshKey], queryFn: fetchTwoThreeSetting ,refetchOnWindowFocus: false});

    const fetchTwoDigits = () => getMethodService(`${TWO_DIGITS_API}?sortDirection=asc`);
    const twoDigitsQuery = useQuery({ queryKey: ['twoDigits',twoDigitsRefreshKey], queryFn: fetchTwoDigits, refetchOnWindowFocus: false});

    const fetchThreeDigits = () => getMethodService(`${THREE_DIGITS_API}?page=${threedPage}&sortDirection=asc`);
    const threeDigitsQuery = useQuery({ queryKey: ['threeDigits',threeDigitsRefreshKey,threedPage], queryFn: fetchThreeDigits, refetchOnWindowFocus: false});

    useEffect(() => {
        if(downlineCountQuery){
            setIsLoading(downlineCountQuery.isLoading);
            if(downlineCountQuery?.data === 'Unauthenticated.' || downlineCountQuery?.data === 'E_UNAUTHORIZED_ACCESS: Unauthorized access'){
                HandleLogout(navigate);
            };
            if(downlineCountQuery?.data?.status === "success"){
                setData(downlineCountQuery?.data?.data);
            };
        };
    }, [downlineCountQuery]);

    useEffect(() => {
        if(twoThreeSettingQuery){
            setIsLoading(twoThreeSettingQuery.isLoading);
            if(twoThreeSettingQuery?.data === 'Unauthenticated.' || twoThreeSettingQuery?.data === 'E_UNAUTHORIZED_ACCESS: Unauthorized access'){
                HandleLogout(navigate);
            };
            if(twoThreeSettingQuery?.data?.status === "success"){
                setTwoThreeSetting(twoThreeSettingQuery?.data?.data);
            };
        };
    }, [twoThreeSettingQuery]);

    useEffect(() => {
        if(twoDigitsQuery){
            setIsLoading(twoDigitsQuery.isLoading);
            if(twoDigitsQuery?.data === 'Unauthenticated.' || twoDigitsQuery?.data === 'E_UNAUTHORIZED_ACCESS: Unauthorized access'){
                HandleLogout(navigate);
            };
            if(twoDigitsQuery?.data?.status === "success"){
                setTwoDigits(twoDigitsQuery?.data?.data);
            };
        };
    }, [twoDigitsQuery]);

    useEffect(() => {
        if(threeDigitsQuery){
            setIsLoading(threeDigitsQuery.isLoading);
            if(threeDigitsQuery?.data === 'Unauthenticated.' || threeDigitsQuery?.data === 'E_UNAUTHORIZED_ACCESS: Unauthorized access'){
                HandleLogout(navigate);
            };
            if(threeDigitsQuery?.data?.status === "success"){
                setThreeDigits(threeDigitsQuery?.data?.data);
            };
        };
    }, [threeDigitsQuery]);

    useEffect(()=>{
        setOdds(editData?.odds);
        setTwit(editData?.twit);
        setMinAmount(editData?.min_amount);
        setMaxAmount(editData?.max_amount);
    },[editData]);

    

    const twoThreedEditSubmitHandler = (e:React.FormEvent<HTMLFormElement>) => {
        const data = {
            odds,
            twit,
            min_amount: +minAmount,
            max_amount: +maxAmount
        };

        if(editData){
            setIsLoading(true);
            postMethodService(
                TWO_THREE_SETTING_EDIT_API(editData?.id),
                data,true
            )
            .then(res => {
                setIsLoading(false);
                if(res === 'Unauthenticated.' || res === 'E_UNAUTHORIZED_ACCESS: Unauthorized access'){
                    HandleLogout(navigate);
                };
                if(res?.status === "success"){
                    setShowTwoThreedSettingEditModal(false);
                    setTwoThreedRefreshKey(prevKey => prevKey + 1);
                };
            });
        };   
    };

    // two digits setting 
    const twoDigitsSettingHandler = (e:React.ChangeEvent<HTMLInputElement>) => {
        console.log(e)
        if(e.target.checked)
        {
            setDigitsId((prev:any) => [...prev,e.target.getAttribute('data-twod-id')]);
        }
        else
        {
            console.log('kkk')
            setDigitsId((prev:any) => prev.filter((digit:string|number) => digit != e.target.getAttribute('data-twod-id')))
        }
    };

    const twoLayoutHandler = (e:any) => {
        const checkBoxs = document.querySelectorAll(`.${classes['inp-cbx-twod']}`);
        checkBoxs.forEach((checkbox:any) => {
            if(checkbox.checked){
                checkbox.checked = false;
            };
        });
        setDigitsId([]);
        setAmount("");
        setTwoLayoutType(e.target.value)
    };
    const amountFixHandler = () => {
        const checkBoxs = document.querySelectorAll(`.${classes['inp-cbx-twod']}`);
        const data = {
            ids: digitsId,
            ...(twoLayoutType === 'mm' && { mm_amount: +amount }),
            ...(twoLayoutType === 'gold' && { gl_amount: +amount }),
            ...(twoLayoutType === 'dubai' && { db_amount: +amount }),
            ...(twoLayoutType === 'malay' && { ml_amount: +amount }),
        };
        setIsLoading(true);
        postMethodService(
            `${TWO_DIGITS_API}/update`,
            data,true
        )
        .then(res => {
            setIsLoading(false);
            if(res === 'Unauthenticated.' || res === 'E_UNAUTHORIZED_ACCESS: Unauthorized access'){
                HandleLogout(navigate);
            };
            if(res?.status === "success"){
                checkBoxs.forEach((checkbox:any) => {
                    if(checkbox.checked){
                        checkbox.checked = false;
                    };
                });
                setTwoDigitsRefreshKey(prevKey => prevKey + 1);
                setDigitsId([]);
            };
        });
    };

    const digitsStatusHandler = (status:any) => {
        const checkBoxs = document.querySelectorAll(`.${classes['inp-cbx-twod']}`);
        const data = {
            ids: digitsId,
            ...(twoLayoutType === 'mm' && { mm_status: status }),
            ...(twoLayoutType === 'gold' && { gl_status: status }),
            ...(twoLayoutType === 'dubai' && { db_status: status }),
            ...(twoLayoutType === 'malay' && { ml_status: status }),
        };
        setIsLoading(true);
        postMethodService(
            `${TWO_DIGITS_API}/update`,
            data,true
        )
        .then(res => {
            setIsLoading(false);
            if(res === 'Unauthenticated.' || res === 'E_UNAUTHORIZED_ACCESS: Unauthorized access'){
                HandleLogout(navigate);
            };
            if(res?.status === "success"){
                checkBoxs.forEach((checkbox:any) => {
                    if(checkbox.checked){
                        checkbox.checked = false;
                    };
                });
                setTwoDigitsRefreshKey(prevKey => prevKey + 1);
                setDigitsId([]);
            };
        });
    };

    // two digits setting end

    // three digits setting

    const threeDigitsSettingHandler = (e:React.ChangeEvent<HTMLInputElement>) => {
        if(e.target.checked)
        {
            setThreeDigitsId((prev:any) => [...prev,e.target.getAttribute('data-threed-id')]);
        }
        else
        {
            setThreeDigitsId((prev:any) => prev.filter((digit:string|number) => digit != e.target.getAttribute('data-threed-id')))
        }
    };

    const threeLayoutHandler = (e:any) => {
        const checkBoxs = document.querySelectorAll(`.${classes['inp-cbx-threed']}`);
        checkBoxs.forEach((checkbox:any) => {
            if(checkbox.checked){
                checkbox.checked = false;
            };
        });
        setThreeDigitsId([]);
        setAmount("");
        setThreeLayoutType(e.target.value)
    };

    const threedAmountFixHandler = () => {
        const checkBoxs = document.querySelectorAll(`.${classes['inp-cbx-threed']}`);
        const data = {
            ids: threeDigitsId,
            ...(threeLayoutType === 'mm' && { mm_amount: +amount }),
            ...(threeLayoutType === 'dubai' && { db_amount: +amount }),
        };

        setIsLoading(true);
        postMethodService(
            `${THREE_DIGITS_API}/update`,
            data,true
        )
        .then(res => {
            setIsLoading(false);
            if(res === 'Unauthenticated.' || res === 'E_UNAUTHORIZED_ACCESS: Unauthorized access'){
                HandleLogout(navigate);
            };
            if(res?.status === "success"){
                checkBoxs.forEach((checkbox:any) => {
                    if(checkbox.checked){
                        checkbox.checked = false;
                    };
                });
                setThreeDigitsRefreshKey(prevKey => prevKey + 1);
                setThreeDigitsId([]);
            };
        });
    };

    const threeDigitsStatusHandler = (status:any) => {
        const checkBoxs = document.querySelectorAll(`.${classes['inp-cbx-threed']}`);
        const data = {
            ids: threeDigitsId,
            ...(threeLayoutType === 'mm' && { mm_status: status }),
            ...(threeLayoutType === 'dubai' && { db_status: status }),
        };
        setIsLoading(true);
        postMethodService(
            `${THREE_DIGITS_API}/update`,
            data,true
        )
        .then(res => {
            setIsLoading(false);
            if(res === 'Unauthenticated.' || res === 'E_UNAUTHORIZED_ACCESS: Unauthorized access'){
                HandleLogout(navigate);
            };
            if(res?.status === "success"){
                checkBoxs.forEach((checkbox:any) => {
                    if(checkbox.checked){
                        checkbox.checked = false;
                    };
                });
                setThreeDigitsRefreshKey(prevKey => prevKey + 1);
                setThreeDigitsId([]);
            };
        });
    };

    // three digits setting end

    const setTwoDigitCheck = (e:any) => {
        let value = e.target.value;  
        const checkBoxs = document.querySelectorAll(`.${classes['inp-cbx-twod']}`);
        checkBoxs.forEach((checkbox:any) => {
                checkbox.checked = false;
           
        });
        setDigitsId([])
        if (value === 'all') {
            let digits = [];    
            for (let i = 0; i < 100; i++) {
                const formattedNumber = i.toString().padStart(2, '0');
                console.log(formattedNumber)
                let checkbox : any = document.getElementById(`${formattedNumber}-twod`);
                checkbox.checked = true;
                digits.push(formattedNumber);
            }
            setDigitsId(digits)
        }else {
            let digits = [];    
        for (let index = 0; index <= 9; index++) {
            let number = `${value}${index}`;
            let checkbox : any = document.getElementById(`${number}-twod`);
            checkbox.checked = true;
            digits.push(number)
        }
        setDigitsId(digits)
        }
        
    }
  return (
    <>
     
            <div className="card mb-3">
                <div className="card-header">
                    <div className="row align-items-center justify-content-between">
                        <div className="col-lg-3">
                            <h4 className="card-title">
                                Dashboard
                            </h4>
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    <div className="row">
                        <div className="col-sm-6 col-lg-3 mb-3 mb-lg-5">
                            <a className="card card-hover-shadow h-100" href="#">
                                <div className="card-body">
                                    <h6 className="card-subtitle">Total Senior</h6>

                                    <div className="row align-items-center gx-2 mb-1">
                                        <div className="col-6">
                                            <h2 className="card-title text-inherit">{data?.senior_count ?? 0}</h2>
                                        </div>
                                        <div className="col-6">
                                            <div className="chartjs-custom" style={{height: '3rem'}}/>
                                        </div>
                                    </div>
                                    <span className="badge bg-soft-success text-success">
                                        <i className="bi-graph-up"></i> {Number(data?.senior_amount ?? 0) / 100}%
                                    </span>
                                    <span className="text-body fs-6 ms-1">{amountCommasSparated(Number(data?.senior_amount ?? 0))} MMK</span>
                                </div>
                            </a>
                        </div>
                        <div className="col-sm-6 col-lg-3 mb-3 mb-lg-5">
                            <a className="card card-hover-shadow h-100" href="#">
                                <div className="card-body">
                                    <h6 className="card-subtitle">Total Master</h6>

                                    <div className="row align-items-center gx-2 mb-1">
                                        <div className="col-6">
                                            <h2 className="card-title text-inherit">{data?.master_count ?? 0}</h2>
                                        </div>
                                        <div className="col-6">
                                            <div className="chartjs-custom" style={{height: '3rem'}}/>
                                        </div>
                                    </div>
                                    <span className="badge bg-soft-success text-success">
                                        <i className="bi-graph-up"></i> {Number(data?.master_amount ?? 0) / 100}%
                                    </span>
                                    <span className="text-body fs-6 ms-1">{amountCommasSparated(Number(data?.master_amount ?? 0))} MMK</span>
                                </div>
                            </a>
                        </div>
                        <div className="col-sm-6 col-lg-3 mb-3 mb-lg-5">
                            <a className="card card-hover-shadow h-100" href="#">
                                <div className="card-body">
                                    <h6 className="card-subtitle">Total Agent</h6>

                                    <div className="row align-items-center gx-2 mb-1">
                                        <div className="col-6">
                                            <h2 className="card-title text-inherit">{data?.agent_count ?? 0}</h2>
                                        </div>
                                        <div className="col-6">
                                            <div className="chartjs-custom" style={{height: '3rem'}}/>
                                        </div>
                                    </div>
                                    <span className="badge bg-soft-success text-success">
                                        <i className="bi-graph-up"></i> {Number(data?.agent_amount ?? 0) / 100}%
                                    </span>
                                    <span className="text-body fs-6 ms-1">{amountCommasSparated(Number(data?.agent_amount ?? 0))} MMK</span>
                                </div>
                            </a>
                        </div>
                        <div className="col-sm-6 col-lg-3 mb-3 mb-lg-5">
                            <a className="card card-hover-shadow h-100" href="#">
                                <div className="card-body">
                                    <h6 className="card-subtitle">Total User</h6>

                                    <div className="row align-items-center gx-2 mb-1">
                                        <div className="col-6">
                                            <h2 className="card-title text-inherit">{data?.user_count ?? 0}</h2>
                                        </div>
                                        <div className="col-6">
                                            <div className="chartjs-custom" style={{height: '3rem'}}/>
                                        </div>
                                    </div>
                                    <span className="badge bg-soft-success text-success">
                                        <i className="bi-graph-up"></i> {Number(data?.user_amount ?? 0) / 100}%
                                    </span>
                                    <span className="text-body fs-6 ms-1">{amountCommasSparated(Number(data?.user_amount ?? 0))} MMK</span>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card mb-3">
                <div className="card-header">
                    <div className="row align-items-center justify-content-between">
                        <div className="col-lg-3">
                            <h4 className="card-title">
                                2D / 3D Setting
                            </h4>
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    <div className="row">
                        <div className="col-sm-12">
                            <TableScroll>
                                <div>
                                    <table className="table text-white w-100 table-bordered table-striped">
                                        <thead>
                                            <tr style={{backgroundColor: 'rgb(70 70 227)'}}>
                                                <th className="p-2 text-center text-light">No</th>
                                                <th className="p-2 text-center text-light">Odds</th>
                                                <th className="p-2 text-center text-light">Twit</th>
                                                <th className="p-2 text-center text-light">Min</th>
                                                <th className="p-2 text-center text-light">Max</th>
                                                <th className="p-2 text-center text-light">Type</th>
                                                <th className="p-2 text-center text-light">Action</th>
                                                <th className="p-2 text-center text-light">Date</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {isLoading && <TableLoading col={8} />}
                                            {
                                                twoThreeSetting.length > 0 ?
                                                twoThreeSetting.map((setting:stateObjType,i: number) =>
                                                    <tr style={{verticalAlign: 'middle'}} key={i}>
                                                        <td className="p-2 text-center text-dark">{i + 1}</td>
                                                        <td className="p-2 text-center text-dark">{setting?.odds}</td>
                                                        <td className="p-2 text-center text-dark">{setting?.twit ?? '--'}</td>
                                                        <td className="p-2 text-end text-dark">{amountCommasSparated(setting?.min_amount)}</td>
                                                        <td className="p-2 text-end text-dark">{amountCommasSparated(setting?.max_amount)}</td>
                                                        <td className="p-2 text-center text-dark">
                                                            <span className='badge bg-dark'>{settingType(`${setting?.type } ${setting?.is_digit}`)}</span>
                                                        </td>
                                                        <td className="p-2 text-center text-dark">
                                                            <button className='btn btn-outline-success py-1' onClick={() => {
                                                                setShowTwoThreedSettingEditModal(true);
                                                                setEditData(setting);
                                                            }}>
                                                                Edit
                                                            </button>
                                                        </td>
                                                        <td className="p-2 text-center text-dark">{setting?.updated_at}</td>
                                                    </tr>
                                                )
                                                :
                                                <tr>
                                                    <td className='text-center' colSpan={8}>No found data</td>
                                                </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </TableScroll>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card mb-3">
                <div className="card-header">
                    <div className="row align-items-center justify-content-between">
                        <div className="col-sm-6">
                            <h4 className="card-title">
                                MM 2D ( Limit, Open / Close )
                            </h4>
                        </div>
                        
                        <div className="col-sm-4">
                            <select className='form-control mt-3 mt-sm-0' value={twoLayoutType} onChange={twoLayoutHandler}>
                                <option value="mm">Myanmar 2D</option>
                                <option value="gold">Gold 2D</option>
                                <option value="dubai">Dubai 2D</option>
                                <option value="malay">Malay 2D</option>
                            </select>
                        </div>
                    </div>
                    <div className="row align-items-center justify-content-between" >
                        
                        <div className="col-sm-4">
                            <select className='form-control mt-3 mt-sm-0'  onChange={setTwoDigitCheck}>
                                <option value="" disabled selected hidden>Select Digits</option>
                                <option value="all">All</option>
                                <option value="0">0</option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                                <option value="6">6</option>
                                <option value="7">7</option>
                                <option value="8">8</option>
                                <option value="9">9</option>
                            </select>
                        </div>

                    </div>
                </div>
                <div className="card-body">
                    {
                        digitsId.length > 0 &&
                        <div className="row">
                            <div className='col-12 col-md-6'>
                                <div className='mb-2 mb-md-0'>
                                    <button type='button' className='btn btn-outline-success rounded-0 me-2' onClick={()=> digitsStatusHandler("0")}>Enable</button>
                                    <button type='button' className='btn btn-outline-danger rounded-0' onClick={()=> digitsStatusHandler("1")}>Disable</button>
                                    <button type='button' style={{marginLeft : '8px'}} className='btn btn-outline-warning rounded-0' onClick={()=> digitsStatusHandler("2")}>Limit Close</button>
                                </div>
                            </div>
                            <div className='col-12 col-md-6'>
                                <div className='d-flex align-items-center justify-content-end'>
                                    <input type="number" value={amount} className='form-control me-2 rounded-0 h-100' onChange={(e:React.ChangeEvent<HTMLInputElement>) => setAmount(e.target.value)} style={{maxWidth: '250px'}} placeholder='Amount'/>
                                    <button type='button' className='btn btn-outline-success rounded-0' onClick={amountFixHandler}>submit</button>
                                </div>
                            </div>
                            
                        </div>
                    }
                    <div className="row">
                        <div className='col-12'>
                            <div className='mt-2 d-flex flex-wrap align-items-center justify-content-center'>
                                {
                                    twoDigits.length > 0 &&
                                    twoDigits.map((digit:stateObjType) => 
                                        <div key={digit?.twoDigit?.number}  style={{padding: '15px 5px'}}>
                                            <div className='d-flex align-items-center justify-content-start'>
                                                <input className={classes['inp-cbx-twod']} id={`${digit?.twoDigit?.number}-twod`} data-twod-id={`${digit?.twoDigit?.number}`} type="checkbox" style={{display: 'none'}} onChange={twoDigitsSettingHandler}/>
                                                <label className={`${classes.cbx} d-flex`} htmlFor={`${digit?.twoDigit?.number}-twod`}>
                                                    <span>
                                                        <svg width="12px" height="10px" viewBox="0 0 12 10">
                                                            <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                                                        </svg>
                                                    </span>
                                                    {
                                                        twoLayoutType == "mm" &&
                                                        <span className={`${digit?.mm_status === 1 && 'text-danger fw-bolder'}`}>{digit.twoDigit?.number}</span>
                                                    }
                                                    {
                                                        twoLayoutType == "gold" &&
                                                        <span className={`${digit?.gl_status === 1 && 'text-danger fw-bolder'}`}>{digit.twoDigit?.number}</span>
                                                    }
                                                    {
                                                        twoLayoutType == "dubai" &&
                                                        <span className={`${digit?.db_status === 1 && 'text-danger fw-bolder'}`}>{digit.twoDigit?.number}</span>
                                                    }
                                                    {
                                                        twoLayoutType == "malay" &&
                                                        <span className={`${digit?.ml_status === 1 && 'text-danger fw-bolder'}`}>{digit.twoDigit?.number}</span>
                                                    }
                                                </label>
                                                    {
                                                        
                                                        twoLayoutType == "mm" &&
                                                        <span className={`badge ${(digit?.mm_status === 0 && 'bg-warning' )|| (digit?.mm_status === 1 && 'bg-danger') ||  (digit?.mm_status === 2 && 'bg-secondary')} ms-1 d-block ${(digit?.mm_amount === null) && 'p-0'}`} style={{minWidth: '50px'}}>{amountCommasSparated(digit?.mm_amount ?? '') }</span>
                                                    }
                                                    {
                                                        twoLayoutType == "gold" &&
                                                        <span className={`badge ${digit?.gl_status === 1 ? 'bg-danger' : 'bg-warning'} ms-1 d-block ${(digit?.gl_amount === null) && 'p-0'}`} style={{minWidth: '50px'}}>{amountCommasSparated(digit?.gl_amount ?? '') }</span>
                                                    }
                                                    {
                                                        twoLayoutType == "dubai" &&
                                                        <span className={`badge ${digit?.db_status === 1 ? 'bg-danger' : 'bg-warning'} ms-1 d-block ${(digit?.db_amount === null) && 'p-0'}`} style={{minWidth: '50px'}}>{amountCommasSparated(digit?.db_amount ?? '') }</span>
                                                    }
                                                    {
                                                        twoLayoutType == "malay" &&
                                                        <span className={`badge ${digit?.ml_status === 1 ? 'bg-danger' : 'bg-warning'} ms-1 d-block ${(digit?.ml_amount === null) && 'p-0'}`} style={{minWidth: '50px'}}>{amountCommasSparated(digit?.ml_amount ?? '') }</span>
                                                    }
                                            </div>
                                        </div>    
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card mb-3">
                <div className="card-header">
                    <div className="row align-items-center justify-content-between">
                        <div className="col-sm-6">
                            <h4 className="card-title">
                                MM 3D ( Limit, Open / Close)
                            </h4>
                        </div>
                        <div className="col-sm-3">
                            <select className='form-control mt-3 mt-sm-0' onChange={(e:React.ChangeEvent<HTMLInputElement>| any) => setThreedPage(e.target.value)}>
                                <option value="1">000 - 099</option>
                                <option value="2">100 - 199</option>
                                <option value="3">200 - 299</option>
                                <option value="4">300 - 399</option>
                                <option value="5">400 - 499</option>
                                <option value="6">500 - 599</option>
                                <option value="7">600 - 699</option>
                                <option value="8">700 - 799</option>
                                <option value="9">800 - 899</option>
                                <option value="10">900 - 999</option>
                            </select>
                        </div>
                        <div className="col-sm-3">
                            <select className='form-control mt-3 mt-sm-0' value={threeLayoutType} onChange={threeLayoutHandler}>
                                <option value="mm">Myanmar 3D</option>
                                <option value="dubai">Dubai 3D</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    {
                        isLoading &&
                        <div className='text-center'>
                            <div
                                className="spinner-border spinner-border-sm"
                                role="status"
                            >
                                <span className="visually-hidden">
                                    Loading...
                                </span>
                            </div>
                        </div>
                    }
                    {
                        threeDigitsId.length > 0 &&
                        <div className="row">
                            <div className='col-12 col-md-6'>
                                <div className='mb-2 mb-md-0'>
                                    <button type='button' className='btn btn-outline-success rounded-0 me-2' onClick={()=> threeDigitsStatusHandler("0")}>Enable</button>
                                    <button type='button' className='btn btn-outline-danger rounded-0' onClick={()=> threeDigitsStatusHandler("1")}>Disable</button>
                                </div>
                            </div>
                            <div className='col-12 col-md-6'>
                                <div className='d-flex align-items-center justify-content-end'>
                                    <input type="number" value={amount} className='form-control me-2 rounded-0 h-100' onChange={(e:React.ChangeEvent<HTMLInputElement>) => setAmount(e.target.value)} style={{maxWidth: '250px'}} placeholder='Amount'/>
                                    <button type='button' className='btn btn-outline-success rounded-0' onClick={threedAmountFixHandler}>submit</button>
                                </div>
                            </div>
                        </div>
                    }
                    <div className="row">
                        <div className='col-12'>
                            <div className='mt-2 d-flex flex-wrap align-items-center justify-content-center'>
                                {
                                    threeDigits.length > 0 &&
                                    threeDigits.map((digit:stateObjType) => 
                                        <div key={digit?.id}  style={{padding: '15px 5px'}}>
                                            <div className='d-flex align-items-center justify-content-start'>
                                                <input className={classes['inp-cbx-threed']} id={`${digit?.id}-threed`} data-threed-id={`${digit?.id}`} type="checkbox" style={{display: 'none'}} onChange={threeDigitsSettingHandler}/>
                                                <label className={`${classes.cbx} d-flex`} htmlFor={`${digit?.id}-threed`}>
                                                    <span>
                                                        <svg width="12px" height="10px" viewBox="0 0 12 10">
                                                            <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                                                        </svg>
                                                    </span>
                                                    {
                                                        threeLayoutType == "mm" &&
                                                        <span className={`${digit?.mm_status === 1 && 'text-danger fw-bolder'}`}>{digit?.number}</span>
                                                    }
                                                    {
                                                        threeLayoutType == "dubai" &&
                                                        <span className={`${digit?.db_status === 1 && 'text-danger fw-bolder'}`}>{digit?.number}</span>
                                                    }
                                                </label>
                                                    {
                                                        threeLayoutType == "mm" &&
                                                        <span className={`badge ${digit?.mm_status === 1 ? 'bg-danger' : 'bg-warning'} ms-1 d-block ${(digit?.mm_amount === null) && 'p-0'}`} style={{minWidth: '50px'}}>{amountCommasSparated(digit?.mm_amount ?? '') }</span>
                                                    }
                                                    {
                                                        threeLayoutType == "dubai" &&
                                                        <span className={`badge ${digit?.db_status === 1 ? 'bg-danger' : 'bg-warning'} ms-1 d-block ${(digit?.db_amount === null) && 'p-0'}`} style={{minWidth: '50px'}}>{amountCommasSparated(digit?.db_amount ?? '') }</span>
                                                    }
                                            </div>
                                        </div>    
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
       <ToastContainer />
       {showTwoThreedSettingEditModal && (
            <ModalBox
                title="Update Setting"
                onClose={() => setShowTwoThreedSettingEditModal(false)}
                onSubmit={twoThreedEditSubmitHandler}
                isLoading={isLoading}
            >
                <Form>
                    <FormGroup>
                        <Label for="amount">Odds</Label>
                        <Input type="number" value={odds} onChange={(e:React.ChangeEvent<HTMLInputElement>)=>setOdds(e.target.value)}/>
                    </FormGroup>
                    {
                        editData?.is_digit === "Three" &&
                        <FormGroup>
                            <Label for="amount">Twit</Label>
                            <Input type="number" value={twit} onChange={(e:React.ChangeEvent<HTMLInputElement>)=>setTwit(e.target.value)}/>
                        </FormGroup>
                    }
                    <FormGroup>
                        <Label for="amount">Min Amount</Label>
                        <Input type="number" value={minAmount} onChange={(e:React.ChangeEvent<HTMLInputElement>)=>setMinAmount(e.target.value)}/>
                    </FormGroup>
                    <FormGroup>
                        <Label for="amount">Max Amount</Label>
                        <Input type="number" value={maxAmount} onChange={(e:React.ChangeEvent<HTMLInputElement>)=>setMaxAmount(e.target.value)}/>
                    </FormGroup>
                </Form>
            </ModalBox>
        )}
    </>
  )
}

export default Dashboard
