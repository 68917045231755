import React, { useEffect, useState , useCallback} from 'react'
import Template from '../utils/Template'
import { ToastContainer } from 'react-toastify'
import { getMethodService, postMethodService } from '../services';
import { CHANGE_PASSWORD_API, LOGIN_API, REPORT_API, WINNER_API } from '../services/constants';
import { useNavigate } from 'react-router-dom';
import { HandleLogout, amountCommasSparated } from '../utils/Helper';
import TableScroll from '../utils/TableScroll';
import TableLoading from '../utils/TableLoading';
import { useQuery } from '@tanstack/react-query';
import { stateObjType } from '../types';
import { DateRangePickerComponent } from '../utils/DateRange';

const Report = ({type,endPoint,reportType}:any) => {
  const [isLoading,setIsLoading] = useState(false);
  const [report,setReport] = useState<any>([]);
  const [time,setTime] = useState<any>("1");
  const [customArr,setCustomArr] = useState<any>([]);
  const [finalResult,setFinalResult] = useState<any>([]);
  const [searchDigit,setSearchDigit] = useState<any>("");
  const [searchAmount,setSearchAmount] = useState<any>("");
  const [filterResult,setFilterResult] = useState<any>("");
  const [total , setTotal] = useState(0);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const handleChangeValueDateRangePicker = useCallback(
    (start: any, end: any, label: any) => {
      setStartDate(start.format("YYYY-MM-DD"));
      setEndDate(end.format("YYYY-MM-DD"));
    },
    []
  );

  const navigate = useNavigate();

  const fetchReport = () => getMethodService(`${REPORT_API}${endPoint}?type=${reportType}&time=${time}&start_date=${startDate}&end_date=${endDate}`);
  const reportQuery = useQuery({ queryKey: [type,time, startDate, endDate], queryFn: fetchReport, refetchOnWindowFocus: false});

  useEffect(()=>{
    setTime('1');
    setSearchDigit("");
    setSearchAmount("");
  },[type]);

  useEffect(()=>{
    setSearchDigit("");
    setSearchAmount("");
  },[time]);
  useEffect(()=>{
    if(type === "MM 3D" || type === "Dubai 3D" || type === "Gold 3D"){
        let nums = [];

        for (let i = 0; i < 1000; i++) {
            if (i < 10) {
                const obj = {
                    bet_count: 0,
                    number: `00${i}`,
                    bets: "-",
                    total_amount: 0,
                    bingo: 0,
                    profit: 0,
                };
                nums.push(obj);
            } else if (i < 100) {
                const obj = {
                    bet_count: 0,
                    number: `0${i}`,
                    bets: "-",
                    total_amount: 0,
                    bingo: 0,
                    profit: 0,
                };
                nums.push(obj);
            } else {
                const obj = {
                    bet_count: 0,
                    number: `${i}`,
                    bets: "-",
                    total_amount: 0,
                    bingo: 0,
                    profit: 0,
                };
                nums.push(obj);
            }
          };
        setCustomArr(nums);
    }else{
        let nums = [];
        for(let i = 0;i < 100; i++){
           const obj = {
               bet_count: 0,
               number: i < 10 ? `0${i}`: `${i}`,
               bets: "-",
               total_amount: 0,
               bingo: 0,
               profit: 0,
           };
           nums.push(obj)
        };
        setCustomArr(nums);
    };
  },[type,time]);

  useEffect(() => {
        if(reportQuery){
            setIsLoading(reportQuery.isLoading);
            if(reportQuery?.data === 'Unauthenticated.' || reportQuery?.data === 'E_UNAUTHORIZED_ACCESS: Unauthorized access'){
                HandleLogout(navigate);
            };
          
            if(reportQuery?.data?.status === "success"){
                const reports = reportQuery?.data?.data
                setReport(reports);
                const total = reports.reduce((acc : any, cur : any) => Number(acc) + Number(cur?.total_amount)  ,0)
                setTotal(total)
            };
        };
  }, [reportQuery]);

  useEffect(()=>{
    if(customArr.length > 0){
        customArr.map((cusNum:any) => {
            report.map((num:any) => {
            if(cusNum?.number == num?.number){
                cusNum['bet_count'] = num['bet_count'];
                cusNum['bets'] = num['bets'];
                cusNum['total_amount'] = num['total_amount'];
                cusNum['bingo'] = num['bingo'];
                cusNum['profit'] = num['profit'];
            }
        }); 
        });
        setFinalResult(customArr);
    };  
  },[report]);

  useEffect(()=>{
     if(finalResult.length > 0 && searchDigit.length > 0){
        const filterDigit = finalResult.filter((obj:any) => obj.number.includes(searchDigit));
        setFilterResult(filterDigit);
     };
     if(finalResult.length > 0 && searchAmount > 0){
        const filterDigit = finalResult.filter((obj:any) => obj.total_amount >= searchAmount);
        setFilterResult(filterDigit);
     };
  },[searchDigit,searchAmount]);


  return (
    <>
       
            <div className="card mb-3 mb-lg-5">
                <div className="card-header">
                    <div className="row align-items-center justify-content-between">
                        <div className="col-sm-2">
                            <h4 className="card-title mb-2 mb-sm-0">
                                Report
                            </h4>
                        </div>
                        <div className="col-sm-2">
                            <label className='mb-1'>Search digit</label>
                            <input type='text' className='form-control' value={searchDigit} placeholder='Search digit' onChange={e => {setSearchDigit(e.target.value);setSearchAmount("")}}/>
                        </div>
                        <div className="col-sm-2">
                            <label className='mb-1'>Search amount</label>
                            <input type='text' className='form-control' value={searchAmount} placeholder='Search amount' onChange={e => {setSearchAmount(e.target.value);setSearchDigit("")}}/>
                        </div>
                        <div className="col-sm-2">
                            {
                                type != "Dubai 3D" &&
                                <label className='mb-1'>Search Time</label>
                            }
                            {
                                type === "MM 2D" &&
                                <select className='form-control' value={time} onChange={e => setTime(e.target.value)}>
                                    <option value="1">12:01 PM</option>
                                    <option value="2">04:30 PM</option>
                                </select>
                            }
                            {
                                (type === "Gold 2D" || type === "BTC 2D") &&
                                <select className='form-control' value={time} onChange={e => setTime(e.target.value)}>
                                    <option value="1">09:30 AM</option>
                                    <option value="2">12:00 PM</option>
                                    <option value="3">02:00 PM</option>
                                    <option value="4">04:30 PM</option>
                                    <option value="5">08:00 PM</option>
                                </select>
                            }
                            
                            {
                                type === "Dubai 2D" &&
                                <select className='form-control' value={time} onChange={e => setTime(e.target.value)}>
                                    <option value="1">11:00 AM</option>
                                    <option value="2">01:00 PM</option>
                                    <option value="3">03:00 PM</option>
                                    <option value="4">05:00 PM</option>
                                    <option value="5">07:00 PM</option>
                                    <option value="6">09:00 PM</option>
                                </select>
                            }
                            {
                                type === "Malay 2D" &&
                                <select className='form-control' value={time} onChange={e => setTime(e.target.value)}>
                                    <option value="1">10:00 AM</option>
                                    <option value="2">12:00 PM</option>
                                    <option value="3">02:00 PM</option>
                                    <option value="4">04:00 PM</option>
                                    <option value="5">06:00 PM</option>
                                    <option value="6">08:00 PM</option>
                                </select>
                            }
                            {
                                type === "MM 3D" &&
                                <select className='form-control' value={time} onChange={e => setTime(e.target.value)}>
                                     <option value="1">၁၆ ရက်</option>
                                    <option value="2">၁ ရက်</option>
                                </select>
                            }
                        </div>
                        <div className='col-sm-2' style={{ marginTop : '24px' }}>
                        <DateRangePickerComponent startDate={startDate} endDate={endDate} onChange={handleChangeValueDateRangePicker}/>
                        </div>
                        
                    </div>
                   
                </div>
                <div className="card-body">
                    <div className="row">
                        <div className="col-sm-12">
                            <TableScroll>
                                <div>
                                    <table className="table text-white w-100 table-bordered table-striped">
                                        <thead>
                                            <tr style={{backgroundColor: 'rgb(70 70 227)'}}>
                                                <th className="p-2 text-center text-light">
                                                    {
                                                        (type === "MM 3D" || type === "Dubai 3D" || type === "Gold 3D")? '3D': '2D'
                                                    }
                                                </th>
                                                <th className="p-2 text-center text-light">Bet Count</th>
                                                {searchAmount ? <th className="p-2 text-center text-light">Different Amount</th> : null}
                                                <th className="p-2 text-center text-light">Amount</th>
                                                <th className="p-2 text-center text-light">Bets</th>
                                                <th className="p-2 text-center text-light">Bingo</th>
                                                <th className="p-2 text-center text-light">Profit & Loss</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {isLoading && <TableLoading col={6} />}
                                            {
                                                (searchDigit.length > 0) || (searchAmount.length > 0) ?
                                                    (
                                                        filterResult?.length > 0 ?
                                                            filterResult?.map((rep:stateObjType,i:number) => 
                                                            <tr style={{verticalAlign: 'middle'}} key={i}>
                                                                <td className="p-2 text-center text-dark">
                                                                    <span className='badge bg-dark'>{rep?.number}</span>
                                                                </td>
                                                                <td className="p-2 text-center text-dark">{rep?.bet_count}</td>
                                                              {searchAmount ?  <td className="p-2 text-end text-dark">{amountCommasSparated(rep?.total_amount - searchAmount)}</td> : null} 
                                                              <td className="p-2 text-end text-dark">{amountCommasSparated(rep?.total_amount)}</td>
                                                                <td style={{whiteSpace: 'normal'}} className="p-2 text-center text-dark">{rep?.bets}</td>
                                                                <td className="p-2 text-end text-dark">{amountCommasSparated(rep?.bingo)}</td>
                                                                <td className="p-2 text-end text-dark">{amountCommasSparated((total ? total : 0) - rep?.bingo)}</td>
                                                            </tr>
                                                        ):
                                                        <tr>
                                                            <td className='text-center' colSpan={6}>No found data</td>
                                                        </tr>
                                                    )
                                                
                                                :
                                                finalResult?.length > 0 ?
                                                finalResult?.map((rep:stateObjType,i:number) => 
                                                    <tr style={{verticalAlign: 'middle'}} key={i}>
                                                        <td className="p-2 text-center text-dark">
                                                            <span className='badge bg-dark'>{rep?.number}</span>
                                                        </td>
                                                        <td className="p-2 text-center text-dark">{rep?.bet_count}</td>
                                                        <td className="p-2 text-end text-dark">{amountCommasSparated(rep?.total_amount)}</td>
                                                        <td style={{whiteSpace: 'normal'}} className="p-2 text-center text-dark">{rep?.bets}</td>
                                                        <td className="p-2 text-end text-dark">{amountCommasSparated(rep?.bingo)}</td>
                                                        <td className="p-2 text-end text-dark">{amountCommasSparated((total ? total : 0) - rep?.bingo)}</td>
                                                    </tr>
                                                ):
                                                <tr>
                                                    <td className='text-center' colSpan={6}>No found data</td>
                                                </tr>
                                            }

                                            
                                            {/* {
                                                finalResult?.length > 0?
                                                finalResult?.map((rep:stateObjType,i:number) => 
                                                    <tr style={{verticalAlign: 'middle'}} key={i}>
                                                        <td className="p-2 text-center text-dark">
                                                            <span className='badge bg-dark'>{rep?.number}</span>
                                                        </td>
                                                        <td className="p-2 text-center text-dark">{rep?.bet_count}</td>
                                                        <td style={{whiteSpace: 'normal'}} className="p-2 text-center text-dark">{rep?.bets}</td>
                                                        <td className="p-2 text-end text-dark">{amountCommasSparated(rep?.total_amount)}</td>
                                                        <td className="p-2 text-end text-dark">{amountCommasSparated(rep?.bingo)}</td>
                                                        <td className="p-2 text-end text-dark">{amountCommasSparated(rep?.profit)}</td>
                                                    </tr>
                                                )
                                                :
                                                <tr>
                                                    <td className='text-center' colSpan={6}>No found data</td>
                                                </tr>
                                            } */}
                                        </tbody>
                                        {
                                            report?.length > 0 &&
                                            <tfoot>
                                                <tr>
                                                    <td colSpan={2} className='bg-dark text-end text-white'>Total :</td>
                                                    <td className="p-2 text-end text-dark">
                                                        {
                                                            amountCommasSparated(report.reduce((accumulator:any, currentValue:any) => Number(accumulator) + Number(currentValue?.total_amount), 0))
                                                        }
                                                    </td>
                                                    <td></td>
                                                    <td className="p-2 text-end text-dark">
                                                        {
                                                            amountCommasSparated(report.reduce((accumulator:any, currentValue:any) => Number(accumulator) + Number(currentValue?.bingo), 0))
                                                        }
                                                    </td>
                                                    <td className="p-2 text-end text-dark">
                                                        {
                                                            amountCommasSparated(report.reduce((accumulator:any, currentValue:any) => Number(accumulator) + Number(currentValue?.profit), 0))
                                                        }
                                                    </td>
                                                </tr>
                                            </tfoot>
                                        }
                                    </table>
                                </div>
                            </TableScroll>
                        </div>
                    </div>
                </div>
            </div>
        
        <ToastContainer />
    </>
  )
}

export default Report
