import axios from "axios";

let domain = process.env.REACT_APP_DOMAIN;
let node_domain = process.env.REACT_APP_DOMAIN;

export const PROXY_API = `${domain}api/betsapi/proxy`;


export const LOGIN_API = `${domain}api/admin/login`;
export const CHANGE_PASSWORD_API = `${domain}api/change-password`;
export const DOWNLINE_CHANGE_PASSWORD_API = id => `${domain}api/done-line-change-password/${id}`;
export const AUTH_INFO_API = `${domain}api/auth-info`;
export const DOWNLINE_COUNT_API = `${domain}api/admin/down-line-count-amount`;
export const TWOD_SETTING_API = `${domain}api/two-open-close-time`;
export const TWOD_SETTING_EDIT_API = id => `${domain}api/two-open-close-time/${id}`;
export const THREED_SETTING_API = `${domain}api/three-open-close-time`;
export const THREED_SETTING_EDIT_API = id => `${domain}api/three-open-close-time/${id}`;
export const TWO_THREE_SETTING_API = `${domain}api/odds`;
export const TWO_THREE_SETTING_EDIT_API = id => `${domain}api/odds/${id}/update`;
export const USERS_API = `${domain}api`; // -> dynamic endpoint form component
export const SENIORS_EDIT_API = id =>`${domain}api/update-senior/${id}`;
export const TWO_DIGITS_API = `${domain}api/two-digit`;
export const THREE_DIGITS_API = `${domain}api/three-digit`;
export const CAPTION_API = `${domain}api/caption`;
export const CAPTION_EDIT_API = id =>`${domain}api/caption/${id}/update`;
export const RESULT_API = `${domain}api`;
export const CREATE_SENIOR_API = `${domain}api/create-senior`;
export const CREATE_ADMIN_API = `${domain}api/create-admin`;

export const WINNER_API = `${domain}api`;
export const BET_LISTS_API = `${domain}api`;
export const REPORT_API = `${domain}api`
export const SENIORS_LIST_API = `${domain}api/seniors`
export const SENIOR_REPORT_API = `${domain}api/senior-report`;
export const SAVE_SENIOR_REPORT_API = `${domain}api/save-senior-report`;
export const SENIOR_ALL_REPORT_API = `${domain}api/fixed-senior-report`;
export const BET_USER_TRANSACTION_API = `${domain}api`;



export const SENIOR_BALANCE_TRANS_API = id => `${domain}api/senior-balance-transfer/${id}`;
export const ADMIN_BALANCE_TRANS_API = id => `${domain}api/admin-balance-transfer/${id}`;